import React from 'react'

import CompanyInformation from '../../../Utilities/CompanyInformation'

const AdvancedSearchResultsHeader = () => {

    return (
        <div className="p-5 border-b border-darkGray flex flex-row justify-between items-center">
            <CompanyInformation></CompanyInformation>
        </div>
    )

}

export default AdvancedSearchResultsHeader