import React from 'react'
// import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import useSelectConversation from '../../../../../hooks/conversations/useSelectConversation';
import useSetSelectedConversationUserAndUserSubscriber from '../../../../../hooks/conversations/useSetSelectedConversationUserAndUserSubscriber';
import useUnreadMessages from '../../../../../hooks/conversations/useUnreadMessages';
import useUserInitials from '../../../../../hooks/users/useUserInitials';

const ConversationListItem = ({ conversation }) => {
    const channelId = useSelector(state => state.selectedConversation.channelId);

    const { user, userSubscriber } = useSetSelectedConversationUserAndUserSubscriber(conversation.subscribers)
    const { initials } = useUserInitials(user)
    const { unreadMessages } = useUnreadMessages(userSubscriber, conversation.messages)

    const selectConversation = useSelectConversation()

    return (
        <div className={`flex flex-row items-center py-4 px-2  rounded-md cursor-pointer ${channelId === conversation.id ? 'bg-blue bg-opacity-30' : 'hover:bg-gray'} `} onClick={() => selectConversation(user, conversation.id)}>
            {user.active_profile_picture ?
                <img alt="" src={user.active_profile_picture.thumbnail_url} className="rounded-full mr-4 h-14" />
                :
                <div className="rounded-full mr-4 h-14 w-14 flex justify-center items-center bg-blue">
                    <p className="urbane-medium text-white">{initials}</p>
                </div>
            }

            <div className="flex flex-col flex-1">
                <p className="urbane-demibold">{user.full_name}</p>
                <p className="text-sm">{user.trade}</p>
            </div>

            {
                unreadMessages.length ? 
                    <div className="flex flex-col items-center font-bold text-sm justify-center bg-unread-circle w-8 h-8 rounded-full text-white">
                        {unreadMessages.length > 9 ? '10+' : unreadMessages.length}
                    </div>
                : null
            }
            
        </div>
    )

}

export default ConversationListItem