import authenticatedAxios from '../axiosWithAuth'

export const getProfile = ( id, params = {} ) => {
    return authenticatedAxios.get(`/tl-user/${id}`, { params: { ...params }});
}





