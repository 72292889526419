import './App.css';
import Routes from './Routes'
import { store, persistor } from './store/store';
import { Provider } from 'react-redux'
import { setBearerToken } from './data/Authentication/authentication';
import { useEffect, useState } from 'react';
import {  BrowserRouter as Router } from "react-router-dom";
import Loading from './Components/Utilities/Loading';
import ScrollToTop from './Components/Utilities/ScrollToTop';
import { PersistGate } from 'redux-persist/integration/react';


function App() {
  
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setBearerToken().then(() => {
      setLoaded(true)
    }).catch(error => {
      console.error("Looks like there was an error");
    });
  }, [])

  return (
    <div id="App" >
      {loaded ?

        <Provider store={store}>
          <PersistGate loading={<Loading></Loading>} persistor={persistor}>
          <Router>
            <ScrollToTop />
            <Routes></Routes>
          </Router>
          </PersistGate>
        </Provider>
        :
        <Loading></Loading>
      }
    </div>
  );
}

export default App;
