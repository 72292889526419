import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

export default function useUnreadMessages(userSubscriber, messages) {
    const [ unreadMessages, setUnreadMessages ] = useState([])

    const chats = useSelector(state => state.conversations.chats)

    useEffect(() => {
        if (userSubscriber) {
            setUnreadMessages(messages.filter(mess => mess.seen === 0 && userSubscriber.id === mess.chat_subscriber_id))
        }
    }, [userSubscriber, messages, chats])

    return { unreadMessages }
}