import React, { useState } from 'react'
import { useDispatch } from 'react-redux';

const AvailabilityFilter = () => {

    const [ available, setAvailable ] = useState(true);
    const [ availableDate, setAvailableDate ] = useState(); 
    const dispatch = useDispatch();

    const setFilter = () => {
   
        if(!available && availableDate){
            dispatch({
                type: 'SET_AVAILABILITY_SEARCH', 
                availability: availableDate,
            })
        } else {
            dispatch({
                type: 'SET_AVAILABILITY_SEARCH', 
                availability: null,
            })
        }
    }

    const handleChange = (val) => {
        if(val === 'available'){
            setAvailable(true);
        } else {
            setAvailable(false);
        }
    }

    return (
        <div className="bg-white rounded p-4 shadow mb-4">
            <div className="flex flex-row justify-between">
                <h5 className="urbane-medium text-lg">Availability</h5>
                <button className="apply-filter-button" onClick={() => setFilter()}>Apply Filter</button>
            </div>
            <div>
                <div className="flex flex-row items-center">
                    <input type="radio" id="available" name="availability" value='available' className="mr-3 h-4 w-4" checked={available} onChange={(evt) => handleChange(evt.target.value)}></input>
                    <label htmlFor="available">Currently available for work</label>
                </div>
                <div className="flex flex-row items-center mb-4">
                    <input type="radio" id="starting" name="availability" value='available-soon' className="mr-3 h-4 w-4" checked={!available} onChange={(evt) => handleChange(evt.target.value)}></input>
                    <label>Available to start work on:</label>
                </div>
                <div>
                    <input type="date" value={availableDate} onChange={(evt) => setAvailableDate(evt.target.value) } placeholder="Select Date" className="border-2 rounded px-2 py-1.5 ml-6"></input>
                </div>
            </div>
        </div>
    )

}

export default AvailabilityFilter