import axios from 'axios'
import { store } from '../store/store'
import { API_URL } from '../environment'

const authenticatedAxios = axios.create({
    baseURL: API_URL
})

const requestHandler = request => {
    request.headers.Authorization = 'Bearer ' + localStorage.getItem('bearer_token');
    return request
}

const responseHandler = response => {
    return response
}

const errorHandler = error => {
    if (error.response.status === 401) {
        store.dispatch({ type: 'SET_CURRENT_USER_TO_NULL' })
    }
    return Promise.reject(error)
}

authenticatedAxios.interceptors.request.use(
    request => requestHandler(request),
    error => errorHandler(error)
)

authenticatedAxios.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
)

export default authenticatedAxios
    
