import React from 'react'
import { getMonthDayYearFormat } from '../../../../helpers/dateTimeHelpers'

const WorkExperienceItem = ({ history }) => {

    if(!history){
        return false;
    }

    return (
        <div className="flex flex-row p-8">
            <div className="ml-7 flex flex-col h-full">
                <p className="text-xl urbane-demibold mb-.5">{history.name && history.name}</p>
                <p className="urbane-medium text-lg mb-2">{history.employer_name && history.employer_name}</p>
                <p className="text-sm">{history.description && history.description}</p>
                <p className="text-sm mt-4">{history.start && history.end && (getMonthDayYearFormat(history.start) + ' - ' + getMonthDayYearFormat(history.end))}</p>
            </div>
        </div>
    )

}

export default WorkExperienceItem