import React, {useEffect } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { markChatMessagesRead } from '../../../../../../data/chat'
import useGetChatMessages from '../../../../../../hooks/useGetMessages'
import MessageBubble from './partials/MessageBubble'

const ConversationBody = () => {

    const conversationBottom = useRef()
    const channelId = useSelector(state => state.selectedConversation.channelId);
    const messages = useSelector(state => state.selectedConversation.messages);

    const { getChatMessages } = useGetChatMessages(channelId)

    const messageList = messages.map((message) => (
        <MessageBubble message={message} key={message.id.toString()}></MessageBubble>
    ))

    useEffect(() => {
        if (messages.filter(mess => mess.subscriber.user_id !== null && !mess.seen).length) {
            markChatMessagesRead(channelId)
        }
        conversationBottom.current.scrollIntoView({ behavior: 'smooth'})
    }, [messages, channelId])

    useEffect(() => {
        getChatMessages()
    }, [channelId])

    return (
        <div className="flex-1 overflow-y-auto" id="messagesBody">
            <div className="flex flex-col p-8 flex-col-reverse">
                <div ref={conversationBottom}></div>
                { messageList.reverse() }
            </div>
        </div>
    )

}

export default ConversationBody