import { useEffect, useRef, useState } from "react";
import { getTradeSuggestions } from "../data/AdvancedSearch/suggestions";

export default function useGetTradesSuggestions (trade = '') {
    const [trades, setTrades] = useState([])

    let tradeSuggsQueue = useRef(null)

    useEffect(() => {
        clearTimeout(tradeSuggsQueue.current)
        
        tradeSuggsQueue.current = setTimeout(() => {
            getTradeSuggestions(trade).then(response => {
                setTrades(response.data.results);
            }).catch(error => {
                console.error(error);
            })
        }, 800)
    }, [trade])

    return [ trades ]
}