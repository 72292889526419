import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import authenticatedAxios from '../../../../data/axiosWithAuth'
import { createChat } from '../../../../data/chat'
import { updateChannels } from '../../../../data/pusher'
import Confirm from '../../../Utilities/Modals/Confirm'
import AvailabilityMatch from './MatchMetrics/AvailabilityMatch'
import LevelOfAchievementMatch from './MatchMetrics/LevelOfAchievementMatch'
import LocationMatch from './MatchMetrics/LocationMatch'
import SafetyTicketsMatch from './MatchMetrics/SafetyTicketsMatch'
import SkillsMatch from './MatchMetrics/SkillsMatch'
import TradeMatch from './MatchMetrics/TradeMatch'

const SearchResultsRow = ({ user }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [canMessage, setCanMessage] = useState(false);
    const availability = user.available_for_work;
    const advancedSearch = useSelector(state => state.advancedSearch)

    const [confirmMessage, setConfirmMessage] = useState()
    const [showConfirmDialogue, setShowConfirmDialogue] = useState(false)
    const [loadingMessaging, setLoadingMessaging] = useState(false)

    const handleChatCreate = async () => {
        setLoadingMessaging(true)
        // check to see if the chat already exists
        const { data } = await authenticatedAxios.get(`/chats/check/${user.id}`)
        let message = null
        if (data.chat) {
            const companySubscriber = data.chat.subscribers.find(sub => sub.access_company_id)
            
            if (companySubscriber) {
                if (companySubscriber.user) {
                    message = `It appears as though ${companySubscriber.user.full_name} has created a chat with this individual. Would you still like to reach out?`
                }
                else {
                    message = `It appears that a chat has already been opened with this user. Would you still like to reach out?`
                }
            }
        }

        if (! message) {
            confirmChatCreate()

            return
        }
        
        setConfirmMessage(message)
        setShowConfirmDialogue(true)
    }

    const confirmChatCreate = () => {
        createChat(user.id).then(response => {
            console.log(response)
            dispatch({
                type: 'SET_SELECTED_CONVERSATION',
                channelId: response.data.chat.id,
                user: user
            })
            updateChannels();
            history.push('/conversations');
        }).catch(error => {
            if (error.response.status === 409) {
                dispatch({
                    type: 'SET_SELECTED_CONVERSATION',
                    channelId: error.response.data.id,
                    user: user
                })
                updateChannels();
                history.push('/conversations');
            }
        })
    }

    useEffect(() => {

        const now = (new Date).getTime()
        const available_date = Date.parse(user.available_for_work_date)

        if (user.available_for_work_date == null && user.available_for_work == null) {
            setCanMessage(true)

            return
        }

        if (! user.available_for_work) {
            setCanMessage(false)

            return
        } 

        if (user.is_available_for_work) {
            setCanMessage(true)

            return
        }

        if (user.available_for_work_date && advancedSearch.availability) {
            const requestedAvailability = Date.parse(advancedSearch.availability)
            
            if (requestedAvailability > available_date) {
                setCanMessage(true)

                return
            } 
        }
        // also check if they are available in the next 4 weeks
        if (user.available_for_work_date && now > (available_date - (1000 * 60 * 60* 24 * 28))) {
            setCanMessage(true)

            return
        }

        setCanMessage(false)       
    }, [availability, user])

    const getInitials = () => {
        if(user){
            return user.first_name[0] + user.last_name[0]
        }
    }

    return (
        <>
            <div className="bg-white rounded-lg mb-4 w-full flex flex-row items-center results-counter" >
                <div 
                    className="flex flex-row items-center py-4 pl-4 w-4/12 cursor-pointer" 
                    title={user.location}
                    onClick={() => history.push(`/profile/${user.id}`)}>
                    {user.active_profile_picture ?
                        <img alt="" src={user.active_profile_picture.thumbnail_url} className="rounded-full mr-1 h-11" />
                        :
                        // <img src="https://via.placeholder.com/45" className="rounded-full mr-1" />
                        <div className="rounded-full mr-2 h-11 w-11 flex justify-center items-center bg-blue">
                            <p className="urbane-light text-white">{getInitials()}</p>
                         </div>
                    }
                    <div className="flex-1">
                        <p className="urbane-demibold leading-tight">{user.full_name}</p>
                        <p className="text-sm leading-tight">{user.trade}</p>
                    </div>
                </div>
                <div className="w-1/12 flex justify-center">
                    <AvailabilityMatch user={user}></AvailabilityMatch>
                </div>
                <div className="w-1/12">
                    <LocationMatch user={user}></LocationMatch>
                </div>
                <div className="w-1/12">
                    <TradeMatch user={user}></TradeMatch>
                </div>
                <div className="w-1/12">
                    <LevelOfAchievementMatch user={user}></LevelOfAchievementMatch>
                </div>
                <div className="w-1/12">
                    <SafetyTicketsMatch user={user}></SafetyTicketsMatch>
                </div>
                <div className="w-1/12">
                    <SkillsMatch user={user}></SkillsMatch>
                </div>
                <div className="pl-6 w-2/12 flex justify-center">
                    {(canMessage && !loadingMessaging)  && <img alt="" src={require('../../../../images/icons/navigation/messages-blue.png').default} className="w-8 cursor-pointer" onClick={() => handleChatCreate()} />}

                    { ( canMessage && loadingMessaging) && <img alt="" src={require('../../../../images/icons/misc/loading.png').default} className="w-8 animate-spin-slow" /> }

                    { (! canMessage) && <img alt="" src={require('../../../../images/icons/navigation/messages.png').default} className="w-8" />}
                </div>
            </div>
            { showConfirmDialogue && 
                <Confirm 
                    message={confirmMessage} 
                    yes={confirmChatCreate }
                    no={() => {
                        setShowConfirmDialogue(false) 
                        setLoadingMessaging(false)
                    }}
                    dismiss={() => {
                        setShowConfirmDialogue(false) 
                        setLoadingMessaging(false)
                    }} /> }
        </>
    )

}

export default SearchResultsRow