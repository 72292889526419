import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { getAdvancedSearchResults, getGenericSearchResults } from '../../../../data/AdvancedSearch/search';
import { getSuggestedResults } from '../../../../data/AdvancedSearch/suggestions';
import ResultsList from './ResultsList'

const SearchResults = () => {

    const advancedSearch = useSelector(state => state.advancedSearch);
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        if (loaded) {
            if (advancedSearch.search_type === 'advanced') {
                if (advancedSearch.endOfInitialResults) {
                    // getSuggestedResults(
                    //     advancedSearch.availability,
                    //     advancedSearch.locations,
                    //     advancedSearch.trades,
                    //     advancedSearch.tickets,
                    //     advancedSearch.skills,
                    //     advancedSearch.page
                    // ).then(response => {
                    //     dispatch({
                    //         type: 'APPEND_SEARCH_RESULTS',
                    //         results: response.data.results.data,
                    //         total_results: response.data.results.total,
                    //         total_pages: response.data.results.last_page,
                    //     })
                    // }).catch(error => {
                    //     console.error(error);
                    // })
                } else if (advancedSearch.page !== 1) {
                    // getAdvancedSearchResults(
                    //     advancedSearch.availability,
                    //     advancedSearch.locations,
                    //     advancedSearch.trades,
                    //     advancedSearch.tickets,
                    //     advancedSearch.skills,
                    //     advancedSearch.page
                    // ).then(response => {
                    //     dispatch({
                    //         type: 'APPEND_SEARCH_RESULTS',
                    //         results: response.data.results.data,
                    //         total_results: response.data.results.total,
                    //         total_pages: response.data.results.last_page,
                    //     })
                    // }).catch(error => {
                    //     console.error(error);
                    // })
                }
            } else {
                if (advancedSearch.page !== 1) {
                    getGenericSearchResults(advancedSearch.search, advancedSearch.page).then(response => {
                        dispatch({
                            type: 'APPEND_SEARCH_RESULTS',
                            results: response.data.results.data,
                            total_results: response.data.results.total,
                            total_pages: response.data.results.last_page,
                            page: advancedSearch.page,
                        })

                    }).catch(error => {
                        console.error(error);
                    })
                }
            }
        } else {
            setLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advancedSearch.page])

    return (
        <div className="p-5 mx-auto">
            <div className="bg-gray py-1 px-2 mt-5 mb-2">
                <h4 className="urbane-medium text-lg">{advancedSearch.search_type === 'advanced' && 'Advanced' } Search Results</h4>
            </div>
            <ResultsList></ResultsList>
        </div>
    )

}

export default SearchResults