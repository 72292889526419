import React, {useEffect, useState} from 'react'
import EndorsementItem from './EndorsementItem'

const EndorsementsOverlay = ({ visible, toggleOverlay, skill }) => {

    const [endorsements, setEndorsements] = useState([]);

    useEffect(() => {
        setEndorsements(skill.endorsements.filter(endorsement => (endorsement.visible === 1 && endorsement.user)));
    }, [skill])

    const endorsementsList = endorsements.map((endorsement) => (
        <EndorsementItem endorsement={endorsement} key={endorsement.id}></EndorsementItem>
    ))

    return (
        <div className="fixed w-screen h-screen overflow-hidden top-0 bottom-0 right-0 left-0 bg-gray bg-opacity-50 flex flex-row justify-center items-center">
            <div className="rounded-md w-1/2 h-1/2 bg-offWhite shadow overflow-hidden absolute">
                <div className="bg-gray p-3 flex flex-row justify-between">
                    <h5 className="text-xl urbane-demibold">Endorsements</h5>
                    <p className="text-xl urbane-demibold cursor-pointer" onClick={() => toggleOverlay()}>X</p>
                </div>
                <div className="p-4">
                    <h6 className="urbane-medium text-lg text-center">{skill.name && skill.name}</h6>
                </div>
                <div className="px-8 overflow-y-auto endorsements-scroll">
                    {endorsementsList}
                </div>
            </div>
        </div>

    )

}

export default EndorsementsOverlay