import React, { useEffect } from 'react'
import AdvancedSearchResultsHeader from './partials/AdvancedSearchResultsHeader/AdvancedSearchResultsHeader'
import Navigation from '../Utilities/Navigation/Navigation'
import SearchResults from './partials/SearchResults/SearchResults'
import AppliedSearchFilters from './partials/SearchResults/AppliedFilters/AppliedSearchFilters'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const AdvancedSearchResults = () => {

    const searchType = useSelector(state => state.advancedSearch.search_type);
    const history = useHistory();

    useEffect(() => {
        if (searchType === null) {
            history.push('/search');
        }
    }, [history, searchType])

    return (
        <>
            <Navigation></Navigation>
            <main className="container mx-auto bg-offWhite rounded-md mt-8 shadow-lg">
                <AdvancedSearchResultsHeader></AdvancedSearchResultsHeader>
                {/* The following will show all of the applied filters */}
                {searchType === 'advanced' && <AppliedSearchFilters></AppliedSearchFilters>}
                <SearchResults></SearchResults>
            </main>
        </>
    )

}

export default AdvancedSearchResults