import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { login } from '../../data/Authentication/authentication'

const LoginForm = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { state } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const submit = () => {
        setError(false);
        if (password && email) {
            login(email, password).then(response => {
                localStorage.setItem('bearer_token', response.data.bearer_token)
                if (response.data.user) {
                    localStorage.setItem('authenticated_tradeslink_user', JSON.stringify(response.data.user))
                    dispatch({ type: 'SET_AUTHENTICATED_TRADESLINK_USER', user: response.data.user })
                }
                dispatch({ type: 'SET_CURRENT_USER', payload: response.data.company })
                dispatch({ type: 'SET_BEARER_TOKEN', payload: response.data.bearer_token })
                dispatch({ type: 'SET_CHAT_TOKEN', token: response.data.company.chat_token})
                if(state && state.from){
                    history.push(state.from.pathname + state.from.search);
                } else {
                    history.push('/');
                }
            }).catch(error => {
                setError(true);
                setErrorMessage(error.response.data.msg);
            })
        } else {
            setError(true);
            setErrorMessage('please enter a valid email & password')
        }
    }

    return (
        <div className="bg-white rounded-md px-12 flex flex-col bg-opacity-90">
            <h2 className="urbane-medium text-center text-4xl my-12">Log In</h2>
            <input placeholder="email" className="w-96 h-12 mb-4 px-4 rounded border-2 focus:outline-none focus:ring focus:border-blue" onChange={(evt) => setEmail(evt.target.value)} />
            <input placeholder="password" className="w-96 h-12 px-4 rounded border-2 focus:outline-none focus:ring focus:border-blue" type="password" onChange={(evt) => setPassword(evt.target.value)} />
            {error && <p className="text-red mt-2 text-sm ml-2">{errorMessage}</p>}
            <button className="my-12 bg-blue rounded-md py-2 text-white urbane-medium text-lg" onClick={() => submit()}>Login</button>
            <Link to="/forgot-password" className="text-center mb-6 text-blue text-lg urbane-medium">forgot password?</Link>
        </div>
    )

}

export default LoginForm