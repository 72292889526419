import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMessages } from "../data/chat";


export default function useGetChatMessages (chat_id) {

    const dispatch = useDispatch()

    function setChatMessages(messages) {
        dispatch({type: 'SET_CHAT_MESSAGES', messages: messages});
    }

    function getChatMessages () {
        getMessages(chat_id)
            .then(response => {
                setChatMessages(response.data.messages)
                var div = document.getElementById("messagesBody");
                div.scrollTop = div.scrollHeight;
            }).catch(error => {
                console.error(error);
                if (error.response.status === 404) {
                    // delete selected conversation form list of chats
                    localStorage.removeItem('localStorage')
                    dispatch({ type: 'CLEAR_SELECTED_CONVERSATION_DATA'})
                }
            })
    }

    return {
        getChatMessages
    }
}