import { useDispatch, useSelector } from "react-redux"

export default function useSetSkillSearch() {
    const dispatch = useDispatch()
    const skills = useSelector(state => state.advancedSearch.skills)

    return (skill) => {
        if (skills.includes(skill)) return

        dispatch({
            type: 'SET_SKILL_SEARCH',
            skill: skill,
        })
    }
}