import { createStore, combineReducers } from 'redux'
import AdvancedSearchReducer from './reducers/AdvancedSearchReducer'
import LoggedInUserReducer from './reducers/LoggedInUserReducer'
import SelectedConversationReducer from './reducers/SelectedConversationReducer'
import { persistStore, persistReducer } from 'redux-persist'
import ConversationsReducer from './reducers/ConversationsReducer'
import AuthenticatedTradeslinkUserReducer from './reducers/AuthenticatedTradeslinkUserReducer'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
    selectedConversation: SelectedConversationReducer,
    loggedInUser: LoggedInUserReducer,
    advancedSearch: AdvancedSearchReducer,
    conversations: ConversationsReducer,
    tradeslink_user: AuthenticatedTradeslinkUserReducer
})

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(persistedReducer)
let persistor = persistStore(store)

export { store, persistor}