import React, { useState } from 'react'
import { useEffect } from 'react';
import { forgotPassword } from '../../data/Authentication/forgot-password';

const ForgotPasswordForm = ({ }) => {

    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(true);
    const [emailError, setEmailError] = useState('');
    const [message, setMessage] = useState('Enter your email below');
    const [title, setTitle] = useState('Forgot Your Password?')
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);

    const validateEmail = () => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        setEmailError('Please enter a valid email address');
        setValid(validateEmail());
    }, [email])

    const submit = () => {
        if (valid) {
            setLoading(true);
            forgotPassword(email).then(response => {
                setTimeout(() => {
                    setComplete(true);
                    setTitle('Success!')
                    setMessage(response.data.msg);
                }, 500)
            }).catch(error => {
                setTimeout(() => {
                    setValid(false);
                    setLoading(false);
                    setEmailError(error.response.data.msg);
                }, 500)
            })
        }
    }

    return (
        <div className="bg-white rounded-md flex flex-col bg-opacity-90 items-center w-1/2 p-12 max-w-2xl">
            <h2 className="urbane-medium text-center text-4xl">{title}</h2>
            <p className="text-center my-6">{message}</p>
            {!complete &&
                <>
                    <input placeholder="password" className="w-3/4 h-12 px-4 rounded border-2 focus:outline-none focus:ring focus:border-blue" type="text" onChange={(evt) => setEmail(evt.target.value)} />
                    <p className="text-xs text-darkRed text-center urbane-medium my-2"> {!valid && emailError}</p>
                    {loading ?
                        <img className="animate-spin-slow w-10 h-10 mt-12" src={require('../../images/icons/misc/loading.png').default} alt="loading" />
                        :
                        <button className="mt-12 py-2 px-12 bg-blue rounded-md py-2 text-white urbane-medium text-lg" onClick={() => submit()}>Forgot Password</button>
                    }
                </>
            }
        </div>
    )

}

export default ForgotPasswordForm