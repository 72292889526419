import authenticatedAxios from '../axiosWithAuth'


export const getTradeSuggestions = ( trade ) => {
    return authenticatedAxios.get(`suggestions/trades?name=${trade}`);
}

export const getSkillSuggestions = ( skill ) => {
    return authenticatedAxios.get(`suggestions/skills?name=${skill}`);
}

export const getLocationSuggestions = ( location ) => {
    return authenticatedAxios.get(`suggestions/locations?location=${location}`);
}

export const getTicketsSuggestions = ( ticket ) => {
    return authenticatedAxios.get(`suggestions/tickets?name=${ticket}`)
}

export const getLevelsOfAchievement = () => {
    return authenticatedAxios.get(`trade-level-of-achievements`);
}
export const getSuggestedResults = (availability, locations, tradesArray, tickets, skills) => {
    let trades = tradesArray.map(trade => {
        return trade.name;
    });
    return authenticatedAxios.post(`/advanced-search/suggestions`,{
        location: locations,
        trades: trades,
        tickets: tickets,
        skills: skills,
        availability: availability
    });
}