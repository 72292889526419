const INITIAL_STATE = {
    user: null
}

const AuthenticatedTradeslinkUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_AUTHENTICATED_TRADESLINK_USER':
            state.user = action.user

            return { ...state }
        
        case 'CLEAR_AUTHENTICATED_TRADESLINK_USER_DATA':
            state.user = null

            return { ...state }

        default:
            return state
    }
}

export default AuthenticatedTradeslinkUserReducer