import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import MatchIndicator from '../MatchIndicator';

const LevelOfAchievementMatch = ({ user }) => {

    const trades = useSelector(state => state.advancedSearch.trades);
    const [levels, setLevels] = useState(0);
    const [matches, setMatches] = useState(0);
    const [title, setTitle] = useState('')
    // console.log("Users trades: ", user.user_trades)
    // console.log("Requested trades: ", trades)

    useEffect(() => {
        let tradesArray = []

        let foundMatches = 0
        let tradeLevels = 0

        if (trades.length > 0) {
            trades.forEach(trade => {
                if (trade.level !== null && trade.level !== '') {
                    tradeLevels++;
                }
                if (user.user_trades.length) {
                    tradesArray = user.user_trades.map(tr => {
                        return `${tr.trade?.name}: ${tr.achievement?.name ?? "Unspecified"}`
                    })
                    if (Array.isArray(tradesArray)) {
                        setTitle(tradesArray.join("\n"))
                    }

                    user.user_trades.forEach(userTrade => {
                        if (userTrade.trade.name.includes(trade.name)) {
                            if (userTrade.achievement && userTrade.achievement.name === trade.level) {
                                foundMatches++;
                            }
                        }
                    })
                }
            })
        }
        setLevels(tradeLevels);
        setMatches(foundMatches);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trades, user])

    const match = () => {
        if (levels > 0) {
            if (matches >= levels && levels !== 0) {
                return 'match'
            } else if (matches > 0) {
                return 'partial_match'
            } else {
                return 'no_match';
            }
        } else {
            return '';
        }
    }

    return (
        <MatchIndicator match={match()} title={title}></MatchIndicator>
    )

}

export default LevelOfAchievementMatch