import React from 'react'

const ViewMessageMedia = ({ url, setVisible }) => {

    return (
        <div className="fixed w-screen h-screen overflow-hidden top-0 bottom-0 right-0 left-0 bg-gray bg-opacity-50 flex flex-row justify-center items-center z-10" >
            <div className="rounded-md max-w-screen-md max-h-full bg-offWhite shadow overflow-hidden absolute p-8">
                <p className="text-xl urbane-demibold cursor-pointer absolute right-4 top-2 z-11 text-black" onClick={() => setVisible()}>X</p>
                <img alt="" src={url} className="object-contain max-w-full"></img>
            </div>
        </div>
    )

}

export default ViewMessageMedia