import React, { useState } from 'react'

const AccountActivationForm = ({ message, title, error, loading, savePassword }) => {

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const save = () => {
        let regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
        let isValid = regEx.test(password);
        if (isValid) {
            if (password === confirmPassword) {
                savePassword(password, confirmPassword);
            } else {
                setConfirmPasswordError(true);
            }
        } else {
            setPasswordError(true);
        }
    }

    return (
        <div className="bg-white rounded-md flex flex-col bg-opacity-90 items-center w-1/2 p-12 max-w-2xl">
            <h2 className="urbane-medium text-center text-4xl">{title}</h2>
            <p className="text-center my-6">{message}</p>
            { !loading && !error &&
                <>
                    <input placeholder="password" className="w-3/4 h-12 px-4 rounded border-2 focus:outline-none focus:ring focus:border-blue" type="password" onChange={(evt) => setPassword(evt.target.value)} />
                    <p className="text-xs text-darkRed text-center urbane-medium my-2"> {passwordError && 'Password must contain 8 characters, 1 uppercase letter, 1 lowercase letter and 1 number.'}</p>
                    <input placeholder="confirm password" className="w-3/4 h-12 px-4 rounded border-2 focus:outline-none focus:ring focus:border-blue" type="password" onChange={(evt) => setConfirmPassword(evt.target.value)} />
                    <p className="text-xs text-darkRed text-center urbane-medium my-2">{confirmPasswordError && "Your passwords don't match"}</p>
                    <button className="mt-12 py-2 px-12 bg-blue rounded-md py-2 text-white urbane-medium text-lg" onClick={() => save()}>Save Password</button>
                </>
            }
        </div>
    )

}

export default AccountActivationForm