import Pusher from 'pusher-js'
import { useDispatch, useSelector } from 'react-redux'
import { key, cluster, appId, secret, encrypted } from '../pusher.json'

const pusher = new Pusher(key, {
    cluster,
    appId,
    secret,
    encrypted,
    key
})

const usePusher = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.loggedInUser.user)
    const chats = useSelector(state => state.conversations.chats)
    const selectedConversation = useSelector(state => state.selectedConversation)

    const setupPusher = () => {
        const channel = pusher.subscribe('access-company.' + user.id)

        channel.bind('new-message', data => {
            // check if chat exists for said message which it should cuz it can only be initiated here
            const chat = chats.find(chat => chat.id == data.chat.id)
            
            if (chat) {
                let messages = [data.message].concat(chat.messages)                

                dispatch({ type: 'SET_CONVERSATION_MESSAGES', messages: messages, chat: data.chat })
                
                if ( [data.chat.id].includes(selectedConversation.channelId)) {
                    
                    dispatch({ type: 'PUSH_NEW_MESSAGE', message: data.message })
                }
            }
        })
    }

    return {
        setupPusher,
        user
    }
}

export default usePusher
