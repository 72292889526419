import React, { useEffect, useState } from 'react'
import EndorsementsModal from './Endorsements/EndorsementsOverlay'


const SkillItem = ({ skill }) => {


    const [visible, setVisible] = useState(false)
    const [endorsements, setEndorsements] = useState(false);

    const toggleOverlay = () => {
        let body = document.querySelector('body');
        if (visible) {
            setVisible(false);
            body.style.overflowY = 'auto';
        } else {
            setVisible(true);
            body.style.overflow = 'hidden';
        }
    }

    useEffect(() => {
        setEndorsements(skill.endorsements.filter(endorsement => (endorsement.visible === 1 && endorsement.user)));
    }, [skill]);

    const endorsementCount = () => {
        return endorsements.length - 1;
    }

    return (
        <div className="border-b p-11 flex flex-row justify-between">
            <div className="">
                <p className="urbane-demibold text-xl mb-1">{skill.name}</p>
                {(endorsements && endorsements.length > 0) &&
                    <>
                        <div className="flex flex-row mb-2 items-center">
                            <p className="">Endorsed by</p>
                            <p className="urbane-demibold mx-1">{skill.endorsements[0].user.full_name}</p>
                            {(skill.endorsements.length > 1) &&
                            <p>and {skill.endorsements.length > 2 ? endorsementCount() + ' others' : '1 other'}</p>
                        }
                        </div>
                        <div className="flex flex-row">
                            {endorsements && endorsements.slice(0, 5).map((endorsement, index) => 
                            endorsement.user.active_profile_picture 
                                ? <img src={endorsement.user.active_profile_picture.thumbnail_url} className="h-9 w-9 object-contain rounded-full mr-1.5" key={index} alt="avatar" />
                                : <img src={require('../../../../images/icons/misc/avatar.png').default} className="h-9 w-9 object-contain rounded-full mr-1.5" key={index} alt="avatar"/>
                             )}


                        </div>
                    </>
                }
            </div>
            <div>
                {endorsements.length > 0 &&
                    <p className="text-blue cursor-pointer" onClick={() => toggleOverlay()} >View all</p>
                }

            </div>
            { visible && <EndorsementsModal visible={visible} toggleOverlay={toggleOverlay} skill={skill}></EndorsementsModal>}
        </div>
    )

}

export default SkillItem