import React from 'react'
import { formatTrades } from '../../../../../helpers/profileHelpers';

const ProfileInformation = ({ user }) => {
    if (!user) {
        return false;
    }

    return (
        <div className="flex flex-row items-center">
            <div>
                {!user.image
                    ? <img alt="" src={require('../../../../../images/icons/misc/avatar.png').default} className="rounded-full w-24 object-contain" />
                    : <img alt="" src={user.image.thumbnail_url} className="rounded-full w-24 object-contain" />
                }
                
            </div>
            <div className="ml-3">
                <h3 className="text-2xl urbane-demibold">{user.full_name && user.full_name}</h3>
                <p className="text-l">{user.trade && formatTrades(user.trade)}</p>
                <p className="urbane-extralight">{user.location && user.location}</p>
            </div>
        </div>
    )

}

export default ProfileInformation