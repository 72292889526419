import React from 'react'
import { useSelector } from 'react-redux';

const AppliedFilterItem = ({ filter, remove }) => {

    const locations = useSelector(state => state.advancedSearch.locations);

    const filterTypeText = () => {
        return filter.type.charAt(0).toUpperCase() + filter.type.slice(1).toLowerCase();
    }

    const showRemove = () => {
        if(filter.name === 'Currently Available' || ( filter.type === 'LOCATION' && locations.length < 2)){
            return false;
        } else {
            return true
        }
    }

    return (
        <div className="flex flex-row items-center justify-between p-1.5 border-blue border rounded mr-2 mb-2 bg-white shadow">
            { filter.level
                ? <p className="text-blue urbane-medium">{filterTypeText() + ': ' + filter.level + " " + filter.name}</p>
                : <p className="text-blue urbane-medium">{filterTypeText() + ': ' + filter.name}</p>
            }
               {showRemove() && <p className="pl-4 pr-2 text-blue urbane-medium text-md cursor-pointer" onClick={() => remove(filter)}>x</p>}
        </div>
    )

}

export default AppliedFilterItem