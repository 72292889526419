import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useFilteredSearchConversations(search) {
    const [filtered, setFiltered] = useState([])

    const conversations = useSelector(state => state.conversations.chats)

    useEffect(() => {
        let results = conversations.filter(conversation => {
            let isMatch = false;
            conversation.subscribers.forEach(subscriber => {
                if (subscriber.user !== null) {
                    if (subscriber.user.full_name.toLowerCase().includes(search.toLowerCase())) {
                        isMatch = true;
                    }
                }
            })
            return isMatch;
        });

        setFiltered(results)
    }, [search, conversations])

    return { filtered }
}