import React from 'react'
import { Link } from 'react-router-dom'

const EndorsementItem = ({ endorsement }) => {

    return (
        <div className="mb-4 flex flex-row items-center justify-between bg-white rounded-md p-3 shadow-md">
            <div className="flex flex-row items-center">
                {endorsement.user.active_profile_picture 
                    ? <img src={endorsement.user.active_profile_picture.thumbnail_url} className="rounded-full mr-4 h-14 object-contain" alt="avatar"/>
                    : <img src={require('../../../../../images/icons/misc/avatar.png').default} className="rounded-full mr-4 h-14 object-contain" alt="avatar"/>
                }
                
                <div className="flex-1">
                    <p className="urbane-medium text-lg">{endorsement.user.full_name && endorsement.user.full_name}</p>
                    <p className="text-sm">{endorsement.user.trade && endorsement.user.trade}</p>
                </div>
            </div>
            <Link to={{pathname:`/profile/${endorsement.user.id}`}} onClick={() => document.querySelector('body').style.overflowY = 'auto'}>
                <p className="text-blue cursor-pointer w-24 ml-4 mr-2">View Profile</p>
            </Link>
        </div>
    )

}

export default EndorsementItem