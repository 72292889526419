import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const useConversationBelongsToAuthenticatedUser =  (user_id) => {
    const [ belongsToUser, setBelongsToUser ] = useState(false)
    const authUser = useSelector(state =>  state.tradeslink_user.user)
    const selectedConversation = useSelector(state => state.selectedConversation)
    const chats = useSelector(state => state.conversations.chats)

    useEffect(() => {
        let currentChat = chats.find(chat => selectedConversation.channelId === chat.id)

        // if they don't have the auth user then they are the company logged in
        if (!authUser) {
            setBelongsToUser(true)

            return
        }

        // not in list of chats:( probably means there is an issue somewhere likely the persisted store plugin
        if (! currentChat) {
            return 
        }
        // check subscribers to verify if the auth user is in there
        let subscriber = currentChat.subscribers.find(sub => sub.user_id === authUser.id)

        // if not then get out
        if (! subscriber) {
            return setBelongsToUser(false)
        }

        if (authUser.id === subscriber.user_id) {
            return setBelongsToUser(true)
        }

        return setBelongsToUser(false)

    }, [user_id, selectedConversation, chats])

    return { belongsToUser }
}

export default useConversationBelongsToAuthenticatedUser

