import React from 'react'
import { useHistory } from 'react-router';

const NoMatch = () => {
    const history = useHistory();

    return (
        <main className="h-screen w-screen flex flex-col justify-center items-center bg-login bg-cover">
            <div className="bg-white rounded-md flex flex-col bg-opacity-90 items-center w-1/2 p-12 max-w-2xl">
                <p className="text-center text-3xl my-6">There's nothing here!</p>
                <button className="mt-12 py-2 px-12 bg-blue rounded-md py-2 text-white urbane-medium text-lg" onClick={() => { history.push('/') }}>Take me Home</button>
            </div>
        </main>
    )

}

export default NoMatch