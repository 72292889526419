import { useDispatch, useSelector } from "react-redux";

export default function useSetTicketsSearch() {
    const dispatch = useDispatch()
    const tickets = useSelector(state => state.advancedSearch.tickets)

    return (ticket, setTicket = null) => {
        if (tickets.includes(ticket)) return

        if (setTicket) {
            setTicket('')
        }

        dispatch({
            type: 'SET_TICKET_SEARCH', 
            ticket: ticket,
        })
    }
}