import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import MatchIndicator from '../MatchIndicator';

const TradeMatch = ({ user }) => {

    const trades = useSelector(state => state.advancedSearch.trades);
    const search = useSelector(state => state.advancedSearch.search)
    const [matches, setMatches] = useState(0);
    const searchType = useSelector(state => state.advancedSearch.search_type);
    const [title, setTitle] = useState('')

    useEffect(() => {
        let array = user.user_trades?.map(tr => {
            return `${tr.trade?.name}`
        })
        
        if (Array.isArray(array)) {
            setTitle(array.join("\n"))
        }

        let foundMatches = 0;
        if (searchType === 'generic') {
            user.trades.forEach(userTrade => {
                if (userTrade.name.toLowerCase().includes(search.toLowerCase())) {
                    foundMatches++;
                }
            })
        } else {
            trades.forEach(trade => {
                user.trades.forEach(userTrade => {
                    if (userTrade.name.toLowerCase().includes(trade.name.toLowerCase())) {
                        foundMatches++;
                    }
                })
            })
        }
        setMatches(foundMatches);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trades, user, search, searchType])

    const match = () => {
        if(searchType === 'generic'){
            if(matches > 0){
                return 'match'
            } else {
                return ''
            }
        } else {
            if(trades.length > 0){
                if(matches >= trades.length){
                    return 'match'
                } else if(matches > 0) {
                    return 'partial_match'
                } else {
                    return 'no_match'
                }
            } else {
                return '';
            }
        }
    }

    return (
        <div onClick={() => {
            let string = `User: ${user.full_name} \n`

            if (user.trades) {
                user.trades.forEach(tr => string += `${tr.name}\n`)
            }

            console.log(string)
        }}>
            <MatchIndicator match={match()} title={title}></MatchIndicator>
        </div>
    )

}

export default TradeMatch