import { useDispatch } from "react-redux";

export default function useSelectConversation() {
    const dispatch = useDispatch()
    
    return (user, channelId) => {
        dispatch({
            type: 'SET_SELECTED_CONVERSATION',
            user: user,
            channelId: channelId
        })
    }
}