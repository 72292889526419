import React from 'react'
import LoginForm from './LoginForm'

const Login = () => {

    return (
        <main className="h-screen w-screen flex flex-col justify-center items-center bg-login bg-cover"> 
            <LoginForm></LoginForm>
        </main>
    )

}

export default Login