import React from 'react'
import SkillItem from './Skillitem'

const Skills = ({skills, user}) => {


    const skillsList = skills.map((skill) => (
        <SkillItem skill={skill} key={skill.id}></SkillItem>
    ))

    return (
        <div className="px-3">
            <div className="bg-gray py-1 px-2">
                <h4 className="urbane-medium text-lg text-center">Skills</h4>
            </div>
            {skills.length > 0
                ? skillsList
                : <p className="p-8 text-center">{user.full_name} hasnt added any skills yet</p>
            }
        </div>
    )

}

export default Skills