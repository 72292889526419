import React from 'react'
import { useHistory } from 'react-router'

const ResetPasswordConfirmation = ({ message }) => {
    
    const history = useHistory();

    return (
        <div className="bg-white rounded-md flex flex-col bg-opacity-90 items-center w-1/2 p-12 max-w-2xl">
            <p className="text-center my-6">{message}</p>
            <button className="mt-12 py-2 px-12 bg-blue rounded-md py-2 text-white urbane-medium text-lg" onClick={() => { history.push('/login') }}>Login</button>
        </div>
    )

}

export default ResetPasswordConfirmation