import React from 'react'
import AvailabilityFilter from './Filters/AvailabilityFilter'
import LocationFilter from './Filters/LocationFilter'
import SkillsFilter from './Filters/SkillsFilter'
import TicketsFilter from './Filters/TicketsFilter'
import TradeFilter from './Filters/TradeFilter'
const AdvancedSearchFiltersMain = () => {

    return (
        <div className="p-5">
            <AvailabilityFilter></AvailabilityFilter>
            <LocationFilter></LocationFilter>
            <TradeFilter></TradeFilter>
            <TicketsFilter></TicketsFilter>
            <SkillsFilter></SkillsFilter>
        </div>
    )

}

export default AdvancedSearchFiltersMain