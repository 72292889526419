import React from 'react'
import WorkExperienceItem from './WorkExperienceItem'

const WorkExperience = ({ workExperience, user }) => {


    const workHistoryList = workExperience.map((history) => (
        <WorkExperienceItem history={history} key={history.id}></WorkExperienceItem>
    ))

    return (
        <div className="px-3">
            <div className="bg-gray py-1 px-2">
                <h4 className="urbane-medium text-lg text-center">Work Experience</h4>
            </div>
            {workExperience.length > 0
                ? workHistoryList
                : <p className="p-8 text-center">{user.full_name} hasnt added their work history yet</p>
            }

        </div>
    )

}

export default WorkExperience