import { useEffect, useState } from "react";

export default function useSortedConversationsByLastMessage(list) {
const [sorted, setSorted] = useState([])

    useEffect(() => {
        let sort = list.sort( (a, b) => {
            return new Date(b.last_message_date) - new Date(a.last_message_date)
        })
        
        setSorted(sort)
    }, [list])
    
    return { sorted }
}