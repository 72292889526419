import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { resetPassword, validateResetToken } from '../../data/Authentication/forgot-password';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => {

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('Loading...');
    const [error, setError] = useState(false);
    const [token, setToken] = useState(null);
    const [complete, setComplete] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        let queryToken = query.get('token');

        validateResetToken(queryToken).then(response => {
            setToken(queryToken);
            setLoading(false);
            setMessage('Reset Your Password');
            setTitle('Enter a new password below');
            setError(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            setTitle('Error');
            setMessage(error.response.data.msg);
            setError(true);
        })
    }, [])

    const savePassword = (password, confirmPassword) => {
        resetPassword(password, confirmPassword, token).then(response => {
            setMessage(response.data.msg);
            setComplete(true);
        }).catch((error) => {
            setTitle('Error');
            setMessage(error.response.data.message);
        })
    }


    return (
        <main className="h-screen w-screen flex flex-col justify-center items-center bg-login bg-cover">
            { !complete
                ? <ResetPasswordForm message={message} title={title} loading={loading} error={error} savePassword={savePassword}></ResetPasswordForm>
                : <ResetPasswordConfirmation message={message}></ResetPasswordConfirmation>
            }
        </main>
    )

}

export default ResetPassword