import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import useSkillsErrors from '../../../../../hooks/search/skills/useSkillsErrors';
import useRemoveFilter from '../../../../../hooks/search/useRemoveFilter';
import useSetSkillSearch from '../../../../../hooks/search/useSetSkillSearch';
import useSkillsSuggestions from '../../../../../hooks/search/useSkillsSuggestions';
import AppliedFilterItem from '../../../../Utilities/AppliedFilterItem'
import SuggestionDropdown from './SuggestionDropDown';


const SkillsFilter = () => {

    const applyFilterRef = useRef()
    const [skill, setSkill] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);

    let skillFilters = useSelector(state => state.advancedSearch.skills);

    const { skills } = useSkillsSuggestions(skill)
    const { error, errorMessage, setError, setErrorMessage } = useSkillsErrors(skillFilters)
    const setSkillSearch = useSetSkillSearch()

    const appendFilter = (name) => {
        if(skillFilters.length > 2){
            setError(true);
            setErrorMessage("A maximum of 3 skills can be added")
        } else if(skillFilters.includes(name)){
            setError(true);
            setErrorMessage("Skill already added")
        } else {
            setSkillSearch(name)
            setSkill('');
        }
    }

    const removeFilter = useRemoveFilter()

    const appliedFilters = skillFilters.map(skill => {
        let item = {
             name: skill,
             type: 'SKILL',
         };
          return <AppliedFilterItem filter={item} remove={removeFilter} key={item.name + item.type}></AppliedFilterItem>
     })
 
 

    return (
        <div className="bg-white rounded p-4 shadow mb-4">
            <div className="flex flex-row justify-between items-center">
                <h5 className="urbane-medium text-lg">Skills</h5>
                <button className="apply-filter-button" onClick={() => { 
                    console.log(applyFilterRef)                  
                    appendFilter(applyFilterRef.current.value)
                }}>Apply Filter</button>
            </div>
            <div className="">
                <p className="mb-2">Input all relevant skills</p>
                <div className="relative inline-block w-full">
                    <input placeholder="Type in skills" className="filter-input" value={skill} 
                    ref={applyFilterRef}
                    onChange={(event) => setSkill(event.target.value)} 
                    onFocus={() => setShowSuggestions(true)} 
                    onBlur={() => setTimeout(() => setShowSuggestions(false), [100])}
                    onKeyDown={(evt) => {
                        if(evt.keyCode === 13){ 
                            appendFilter(evt.target.value);
                            evt.target.blur();
                        }
                    }}
                    ></input>
                    <SuggestionDropdown 
                        items={skills} 
                        visible={showSuggestions} 
                        setItem={name => {
                            appendFilter(name)
                        }}></SuggestionDropdown>
                </div>
                <div className="flex flex-row flex-wrap">
                    {appliedFilters}
                </div>
            </div>
            {error && <p className="text-red -mt-2 text-sm ml-2">{errorMessage}</p>}
        </div>
    )

}


export default SkillsFilter