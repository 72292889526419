const INITIAL_STATE = {
    chats: [],
    last_updated: null,
    token: null,
}

const ConversationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_CHATS': 
            state.chats = action.chats

            return { ...state }

        case 'SET_CHAT_TOKEN':
            state.token = action.token;

            return { ...state }

        case 'SET_CONVERSATION_MESSAGES':

            state.chats = state.chats.map(chat => {
                if (chat.id == action.chat.id) {
                    return { ...chat, messages: action.messages }
                }

                return chat
            })

            return { ...state }

        case 'PUSH_NEW_MESSAGES_TO_CHAT':
            let newChats = state.chats.map(chat => {
                if (chat.id === action.chat_id) {
                    chat.messages = action.messages.concat(chat.messages)
                }

                return chat
            })

            state.chats = newChats

            return { ...state }

        case 'MARK_CHAT_MESSAGES_READ':
            let new_chats = state.chats.map(chat => {
                if (chat.id === action.chat_id) {
                    let newMessages = chat.messages.map( mess => {
                        mess.seen = 1

                        return mess
                    })

                    chat.messages = newMessages
                }

                return chat
            })

            state.chats = new_chats

            return { ...state }

        default:
            return state
    }
}

export default ConversationsReducer