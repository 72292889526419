import { useEffect, useRef, useState } from "react";
import { getTicketsSuggestions } from "../../../data/AdvancedSearch/suggestions";

export default function useTicketsSuggestions (ticket) {
    
    const [tickets, setTickets] = useState([])

    const suggestions = useRef(null)

    useEffect(() => {
        clearTimeout(suggestions.current)
        suggestions.current = setTimeout(() => {
            getTicketsSuggestions(ticket).then(response => {
                setTickets(response.data.results)            
            }).catch( error => console.error(error.response.data))
        }, 800)
    }, [ticket])

    return { tickets }
}