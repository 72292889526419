import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useRemoveLocationFromSearch from '../../../../../hooks/search/locations/useRemoveLocationFromSearch';
import useSetLocationsSearch from '../../../../../hooks/search/locations/useSetLocationsSearch';
import useLocationSuggestions from '../../../../../hooks/search/useLocationSuggestions';
import SuggestionDropdown from './SuggestionDropDown';

const LocationItem = ({location, remove}) => {
    return (
        <div className="flex flex-row items-center justify-between p-1.5 border-blue border rounded mr-2 mb-2 bg-white shadow">
            <p className="text-blue urbane-medium">{location}</p>
            <p className="pl-4 pr-2 text-blue urbane-medium text-md cursor-pointer" onClick={() => remove(location)}>x</p>
        </div>
    )
}

const LocationFilter = () => {
    const [location, setLocation] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);    
    const [errorMessage, setErrorMessage] = useState('');
    const autoCompleteRef = useRef(null);
    const locationFilters = useSelector(state => state.advancedSearch.locations);

    const dispatch = useDispatch();

    const { locations, setLocations, error, setError } = useLocationSuggestions(location)
    const locationsList = useSelector(state => state.advancedSearch.locations)
    const removeLocation = useRemoveLocationFromSearch()
    // const setLocationSearch = useSetLocationsSearch()
    const setLocationSearch = (location) => {
        
        if (locationFilters.includes(location)) return

        dispatch({
            type: 'SET_LOCATION_SEARCH',
            location: location
        })

        setLocation('')
        setLocations([])
    }

    const setFilter = () => {
        
        if (location) {
            
            if (locationFilters.length > 2) {
                setError(true);
                setErrorMessage('A maximum of 3 locations can be added');
            } else if (locationFilters.includes(location)) {
                setErrorMessage('This location has already been added');
                setError(true);
            } else {
                dispatch({
                    type: 'SET_LOCATION_SEARCH',
                    location: location,
                })
            }
        }

        setLocation('')
        
    }

    const appliedFilters = locationsList.map(location => {
        return <LocationItem location={location} key={location} remove={(loc) => {
            removeLocation(loc)
         }} />
    })

    return (
        <div className="bg-white rounded p-4 shadow mb-4">
            <div className="flex flex-row justify-between">
                <h5 className="urbane-medium text-lg">Location</h5>
                <button className="apply-filter-button" onClick={() => setFilter(location)}>Apply Filter</button>
            </div>
            <input placeholder="Type in a location" className="filter-input" 
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                onFocus={() => setShowSuggestions(true)} 
                onBlur={() => setTimeout(() => { setShowSuggestions(false) }, [200])}
                onKeyDown={(evt) => {
                    if(evt.keyCode === 13){ 
                        setFilter();
                        evt.target.blur();
                    }
                }}
                ref={autoCompleteRef}
            ></input>
            <SuggestionDropdown items={locations} visible={showSuggestions} setItem={setLocationSearch}></SuggestionDropdown>
            {error && <p className="text-red -mt-2 text-sm ml-2">{errorMessage}</p>}
            <div className="flex flex-row flex-wrap">
                {appliedFilters}
            </div>
        </div>
    )

}

export default LocationFilter