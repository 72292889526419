import { useEffect, useState, useRef } from "react";
import { getLocationSuggestions } from "../../data/AdvancedSearch/suggestions";

export default function useLocationSuggestions (location) {
    const [locations, setLocations] = useState([])
    const [error, setError] = useState(false)

    let suggestions = useRef(null)

    useEffect(() => {
        setError(false)
    }, [location])

    useEffect(() => {
        clearTimeout(suggestions.current)
        suggestions.current = setTimeout(() => {
            if (location && location.length) {
                getLocationSuggestions(location).then(response => {                
                    setLocations(response.data.results);
                }).catch(error => {
                    console.error(error);
                })
            }
        }, 550)
    }, [location])

    return { locations, setLocations, error, setError }
}