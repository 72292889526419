import { useEffect, useRef, useState } from "react";
import { getSkillSuggestions } from "../../data/AdvancedSearch/suggestions";

export default function useSkillsSuggestions(skill) {
    const [skills, setSkills] = useState([])

    const suggestions = useRef(null)

    useEffect(() => {
        clearTimeout(suggestions.current)
        suggestions.current = setTimeout(() => {
            getSkillSuggestions(skill).then(response => {
                setSkills(response.data.results);
            }).catch(error => {
                console.error(error);
            })
        }, 800)
    }, [skill])

    return { skills }
}