import React, { useEffect, useState } from 'react'
import { getMonthDayYearFormat } from '../../../../../helpers/dateTimeHelpers';

const WorkAvailability = ({ user }) => {

    const availability = user.available_for_work;


    const [color, setColor] = useState('bg-green');
    const [text, setText] = useState('');
    const [showDate, setShowDate] = useState(false);

    useEffect(() => {
        setShowDate(false);
        if (user.available_for_work == null && user.available_for_work_date == null) {
            setColor('bg-yellow')
            setText('User has not set their availability')
        } else if (availability === 0 || availability === false || availability === null) {
            setColor('bg-red');
            setText('Not open to new work opportunities')
        } else if (availability === 1 && user.available_for_work_date) {
            let date = new Date(user.available_for_work_date.split(' ')[0]);
            let today = new Date();
            if (date === today || date < today) {
                setColor('bg-green');
                setText('Currently available for work!')
            } else {
                setColor('bg-yellow');
                setText('Available for work, starting: ');
                setShowDate(true);
            }
        } else if (availability) {
            setColor('bg-green');
            setText('Currently available for work!')
        }
    }, [availability, user.available_for_work_date])


    return (
        <div className={`rounded py-3 px-5 ${color} flex-1 flex justify-center items-center`}>
            <p className="urbane-medium whitespace-nowrap">{text}</p>
            {showDate && 
                <p className="text-sm text-center ml-2">
                    {getMonthDayYearFormat(user.available_for_work_date.split(' ')[0])}
                </p>
            }
        </div>
    )

}

export default WorkAvailability