import React from 'react'
import { getMonthDayYearFormat } from '../../../../helpers/dateTimeHelpers';

const TicketItem = ({ ticket }) => {

    if(!ticket){
        return false;
    }

    const getCredentialImage = () => {
        if(!ticket.type){
            return false;
        }
        switch (ticket.type) {
            case 'certification':
                return require('../../../../images/icons/profile/credentials/certification.png').default;

            case 'affiliation':
                return require('../../../../images/icons/profile/credentials/affiliation.png').default;

            case 'other':
                return require('../../../../images/icons/profile/credentials/other.png').default;

            default: return ''
        }
    }

    const getCredentialTimeText = () => {
        if (ticket.expires === 1) {
            if(!ticket.expiry){
                return false;
            }
            return 'Expires on: ' + getMonthDayYearFormat(ticket.expiry)
        } else {
            if(!ticket.issued){
                return false;
            }
            return 'Completed on: ' + getMonthDayYearFormat(ticket.issued)
        }
    }


    return (
        <div className="flex flex-col items-center w-1/3 mb-2">
            <img src={getCredentialImage()} className="w-18 h-11 object-contain mb-2" alt={ticket.type} />
            <p className="text-lg urbane-demibold text-center">{ticket.name && ticket.name}</p>
            <p className="text-sm text-center">{getCredentialTimeText()}</p>
        </div>
    )

}

export default TicketItem