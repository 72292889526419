import React from 'react'
import { ucFirst } from '../../../../../helpers/stringUtilities';

const SuggestionDropdown = ({items, visible, setItem}) => {

    const suggestions = items.map((item, index) => 
        <div 
            className="p-1 hover:bg-blue rounded hover:bg-opacity-30 hover:text-white cursor-pointer" 
            onClick={() => {
                setItem(item)
            }} 
            onMouseEnter={()=> {
                //
            }}
            key={item.toString()} >
            <p>{ucFirst(item)}</p>
        </div>
    );

    return (
        <div className={`absolute bg-offWhite max-h-64 w-1/2 shadow-lg rounded overflow-y-auto p-4 z-20 ${ !visible && 'hidden'}`}>
            <p className="urbane-medium">Suggestions</p>
            {suggestions}
        </div>
    )

}

export default SuggestionDropdown