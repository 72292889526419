import React from 'react'

const Loading = () => {

    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <img className="animate-spin-slow" src={require('../../images/icons/misc/loading.png').default} alt="loading" />
        </div>
    )

}

export default Loading