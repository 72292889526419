import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAdvancedSearchResults } from '../../../../../data/AdvancedSearch/search';
import AppliedFilterItem from './AppliedFilterItem'

const AppliedSearchFilters = () => {

    const [appliedFilters, setAppliedFilters] = useState([]);
    const dispatch = useDispatch()
    let locationsFilter = useSelector(state => state.advancedSearch.locations);
    let tradesFilter = useSelector(state => state.advancedSearch.trades);
    let availabilityFilter = useSelector(state => state.advancedSearch.availability);
    let ticketsFilter = useSelector(state => state.advancedSearch.tickets);
    let skillsFilter = useSelector(state => state.advancedSearch.skills);
    const [loaded, setLoaded] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let filters = [];
        if(availabilityFilter){
            filters.push({
                name: availabilityFilter,
                type: 'AVAILABILITY'
            })
        } else {
            filters.push({
                name: 'Currently Available',
                type: 'AVAILABILITY'
            })
        }

        if(locationsFilter.length > 0){
            locationsFilter.forEach(location => {
                filters.push({
                    name: location,
                    type: 'LOCATION'
                })
            });
        }
        if(tradesFilter.length > 0){
            tradesFilter.forEach(trade => {
                filters.push({
                    name: trade.name,
                    level: trade.level,
                    type: 'TRADE'
                })
            });
        }
        if(ticketsFilter.length > 0){
            ticketsFilter.forEach(ticket => {
                filters.push({
                    name: ticket,
                    type: 'TICKET'
                })
            })
        }

        if(skillsFilter.length > 0){
            skillsFilter.forEach(skill => {
                filters.push({
                    name: skill,
                    type: 'SKILL'
                })
            })
        }
        setAppliedFilters(filters);
        if(loaded){
            // getAdvancedSearchResults(
            //     availabilityFilter,
            //     locationsFilter, 
            //     tradesFilter, 
            //     ticketsFilter, 
            //     skillsFilter, 
            //     1
            // ).then(response => {
            //     dispatch({
            //         type: 'SET_ADVANCED_SEARCH', 
            //         results: response.data.results.data,
            //         total_results: response.data.results.total,
            //         total_pages: response.data.results.last_page,
            //     })
            // }).catch(error => {
            //     console.error(error);
            // })
        } else {
            setLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationsFilter, tradesFilter, availabilityFilter, ticketsFilter, skillsFilter, loaded, dispatch])


    const removeFilter = (filter) => {
        dispatch({
            type: `REMOVE_${filter.type}_SEARCH`, 
            item: filter,
        })
    }

    const appliedFiltersList = appliedFilters.map((filter, index) => (
        <AppliedFilterItem filter={filter} key={index} remove={removeFilter}></AppliedFilterItem>
    ))

    return (
        <div className="p-5">
            <div className="flex flex-row justify-between items-center mb-4">
                <h4 className="text-lg urbane-medium">Applied Filters</h4>
                <button className="rounded bg-blue text-white py-1.5 px-6" onClick={() => history.push('/search')}>Refine Results</button>
            </div>
            
            <div className="flex flex-row flex-wrap">
                {appliedFiltersList}
        </div>
        </div>
    )

}

export default AppliedSearchFilters