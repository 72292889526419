import React from 'react'
import { getMonthDayYearFormat } from '../../../../helpers/dateTimeHelpers';

const EducationItem = ({ education }) => {

    if(!education){
        return false;
    }

    const getCredentialImage = () => {
        if(!education.type){
            return false;
        }
        switch (education.type) {
            case 'certification':
                return require('../../../../images/icons/profile/credentials/certification.png').default;

            case 'affiliation':
                return require('../../../../images/icons/profile/credentials/affiliation.png').default;

            case 'other':
                return require('../../../../images/icons/profile/credentials/other.png').default;

            default: return ''
        }
    }

    const getCredentialTimeText = () => {

        if (education.expires === 1) {
            if(!education.expires){
                return false;
            }
            return 'Expires on: ' + getMonthDayYearFormat(education.expiry)
        } else {
            if(!education.issued){
                return false;
            }
            return 'Completed on: ' + getMonthDayYearFormat(education.issued)
        }
    }


    return (
        <div className="flex flex-col items-center w-1/3 mb-2">
            <img alt="" src={getCredentialImage()} className="w-18 h-11 object-contain mb-2" />
            <p className="text-lg urbane-demibold text-center">{education.name && education.name}</p>
            <p className="text-sm text-center">{getCredentialTimeText()}</p>
        </div>
    )

}

export default EducationItem