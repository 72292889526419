import { useEffect, useState } from "react";
import { getLevelsOfAchievement } from "../data/AdvancedSearch/suggestions";

export default function useGetTradeLevelSuggestions(trade) {
    const [levels, setLevels] = useState([])

    useEffect(() => {
        getLevelsOfAchievement().then(response => {
            setLevels(response.data.levels);
        }).catch(error => {
            console.error(error);
        })
    }, [])

    return { levels }
}