import { useDispatch } from "react-redux";

export default function useRemoveTradeFromSearch() {
    const dispatch = useDispatch()

    return (trade) => {
        dispatch({
            type: 'REMOVE_TRADE_SEARCH',
            item: trade
        })
    }
}