import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MatchIndicator from '../MatchIndicator';

const AvailabilityMatch = ({ user }) => {

    const advancedSearch = useSelector(state => state.advancedSearch)
    const [title, setTitle] = useState('')
    const now = (new Date).getTime()
    const available_date = Date.parse(user.available_for_work_date)

    const match = () => {        

        if (user.available_for_work_date == null && user.available_for_work == null) {
            return 'partial_match'
        }

        if (! user.available_for_work) return 'no_match'

        if (user.is_available_for_work) {
            return 'match'
        }

        if (user.available_for_work_date && advancedSearch.availability) {
            const requestedAvailability = Date.parse(advancedSearch.availability)
            
            if (requestedAvailability > available_date) {
                return 'match'
            } 
        }

        if (user.available_for_work_date && now > available_date) {
            return 'match'
        }

        return 'no_match'
    }

    useEffect(() => {
        if (user.available_for_work_date == null && user.available_for_work == null) {
            setTitle("This tradesperson has not set their availability status and therefore may be available for work opportunities")
        } else if (user.is_available_for_work || (user.available_for_work_date && now > available_date)) {
            setTitle("Currently available for work")
        } else if (user.available_for_work_date && advancedSearch.availability && Date.parse(advancedSearch.availability) > available_date) {
            setTitle(`This tradesperson will be avaiable for work ${(new Date(available_date)).toLocaleDateString()}`)            
        } else {
            setTitle(user.work_availability_status)
        }

    }, [user])

    return (
        <div onClick={() => {
            console.log(`User: ${user.full_name} \nAvailable for work: ${user.available_for_work} \nAvailable for work date: ${(new Date(Date.parse(user.available_for_work_date)).toLocaleDateString())}`)
        }}>
            <MatchIndicator match={match()} title={title}></MatchIndicator>
        </div>

    )
}

export default AvailabilityMatch