import React from 'react'
import { useSelector } from 'react-redux'

const CompanyInformation = () => {

    const user = useSelector(state => state.loggedInUser.user);

    return (
        <>
            { user &&
                <div className="flex flex-row items-center">
                    <div>
                        {user.logo_url !== null ?
                            <img src={user.logo_url} className="rounded-full h-20 w-20 object-cover" alt={user.name && user.name} />
                            :
                            <img src={require('../../../src/images/icons/misc/avatar.png').default} className="rounded-full h-20 w-20 object-cover" alt={user.name && user.name} />
                        }
                    </div>
                    <div className="ml-3">
                        <h3 className="text-2xl urbane-demibold">{user.name && user.name}</h3>
                        <p className="text-l">Company Account</p>
                        <p className="urbane-extralight">{user.location && user.location}</p>
                    </div>
                </div>
            }
        </>
    )

}

export default CompanyInformation