import { useSelector, useDispatch } from "react-redux";

export default function useRemoveLocationFromSearch() {
    const locations = useSelector(state => state.advancedSearch.locations)
    const dispatch = useDispatch()

    return (location) => {
        dispatch({
            type: 'REMOVE_LOCATION_SEARCH',
            item: {name: location}
        })
    }
}