import React from 'react'
import { useSelector } from 'react-redux'
import MatchIndicator from '../MatchIndicator'

const LocationMatch = ({ user }) => {
    const locations = useSelector(state => state.advancedSearch.locations);
    const search = useSelector(state => state.advancedSearch.search);
    const searchType = useSelector(state => state.advancedSearch.search_type);

    const match = () => {
        if (searchType === 'advanced') {
            if (! user.location) return 'no match'

            let isMatch = false

            locations.forEach(loc => {
                if (user.location.includes(loc)) {
                    isMatch = true
                }
            })

            return isMatch ? 'match' : 'no_match'
        }

        return 'match'
    }

    return (
        <div onClick={() => {
            let string = `User: ${user.full_name} \nLocation: ${user.location}`

            console.log(string)
        }}>
            <MatchIndicator match={match()} title={user.location}></MatchIndicator>
        </div>
    )

}

export default LocationMatch