import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { sendMediaMessage, sendMessage } from '../../../../../../data/chat'
import useConversationBelongsToAuthenticatedUser from '../../../../../../hooks/conversations/useConversationBelongsToAuthenticatedUser'

const ConversationFooter = () => {
    const dispatch = useDispatch()
    const [newMessage, setNewMessage] = useState('')
    const channelId = useSelector(state => state.selectedConversation.channelId)
    const [media, setMedia] = useState(null)
    const [loading, setLoading] = useState(false)
    const { belongsToUser } = useConversationBelongsToAuthenticatedUser()

    const submit = (evt) => {
        
        if (evt.keyCode === 13) {
            if (newMessage || media) {
                setLoading(true);
                if (media) {
                    sendMediaMessage(channelId, newMessage, media).then(response => {
                        dispatch({ type: 'PUSH_NEW_MESSAGE', message: response.data.message })
                        setNewMessage('');
                        setMedia(null);
                        setLoading(false);
                    }).catch(error => {
                        console.error(error);
                        setLoading(false);
                    })
                } else {
                    sendMessage(channelId, newMessage).then(response => {
                        dispatch({ type: 'PUSH_NEW_MESSAGE', message: response.data.message })
                        setNewMessage('');
                        setLoading(false);
                    }).catch(error => {
                        setLoading(false);
                        console.error(error);
                    })
                }
            }
        }
    }

    useEffect(() => {
        setNewMessage('')
        setMedia(null)
    }, [channelId])

    return (
        <div className="py-4 px-8 shadow-top">
            {media &&
                <div className="flex flex-row justify-center">
                    <div className="relative p-6">

                        <img alt="" src={require('../../../../../../images/icons/misc/close.png').default} className="h-5 absolute right-0 -top-1 cursor-pointer" onClick={() => setMedia(null)}></img>
                        <img alt="" src={URL.createObjectURL(media)} className="max-h-48"></img>
                    </div>
                </div>
            }
            <div className="flex flex-row items-center">

                <input 
                    placeholder={belongsToUser ? "Type your message..." : "This thread belongs to another users account..."} 
                    className="flex-1 mr-4 p-4 rounded-md" 
                    onChange={(evt) => setNewMessage(evt.target.value)} 
                    onKeyDown={(evt) => submit(evt)} 
                    value={newMessage} 
                    disabled={! belongsToUser || loading}></input>
                <img alt="" src={require('../../../../../../images/icons/conversations/send.png').default} className="object-contain cursor-pointer w-10 mr-6" onClick={() => submit({ keyCode: 13 })} />
                <div className="flex items-center justify-center relative w-12 h-10">
                    <img alt="" src={require('../../../../../../images/icons/conversations/image.png').default} className="object-contain cursor-pointer"onClick={() => document.getElementById('imageInput').click()} />
                    <input
                        id="imageInput"
                        className="absolute hidden"
                        type="file"
                        onChange={(evt) => setMedia(evt.target.files[0])}
                        accept="image/*" />
                </div>
            </div>
        </div >
    )

}

export default ConversationFooter