import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { getGenericSearchResults } from '../../../data/AdvancedSearch/search';
import NavigationDropDown from './NavigationDropDown';

const Navigation = () => {

    const [search, setSearch] = useState('')
    const dispatch = useDispatch()
    const history = useHistory()
    const chats = useSelector(state => state.conversations.chats)
    const [unreadMessages, setUnreadMessages] = useState(0)

    useEffect(() => {
        setUnreadMessages(
            Object.keys(chats).map(key => chats[key].messages).flat().filter(mess => !mess.seen && mess.subscriber.user_id).length
        )        
    }, [chats])

    const getResults = async (evt) => {
        if(evt.keyCode === 13){
            setSearch(search.trim())

            await setTimeout(() => { /* */}, 200)

            getGenericSearchResults(search.trim(), 1).then(response => {
                let users = response.data.results.data.map(user => {
                    const user_trades_array = user.trades.map(tr => tr.name)
                    return { ...user, user_trades_array: user_trades_array }
                }).sort((a, b) => {        
                    if (b.available_for_work_date == null && b.available_for_work == null) {
                        return 1
                    }
        
                    if (! b.available_for_work) return -1
        
                    if (b.is_available_for_work) {
                        return 1
                    }
        
                    return -1
                })

                dispatch({
                    type: 'SET_GENERIC_SEARCH', 
                    results: users,
                    total_results: response.data.results.total,
                    total_pages: response.data.results.last_page,
                    search: search.trim(),
                })

                dispatch({
                    type: 'RESET_SUGGESTIONS'
                })

                history.push('/results');

                try {
                    // determine how many results have been passed that qualify as available
                    const availableUsers = response.data.results.data.filter(user => {
                        return user.is_available_for_work
                    })
    
                    console.log("# of available users shown in list: " + availableUsers.length)

                    const unavailableUsers = response.data.results.data.filter(user => ! user.is_available_for_work )

                    console.log("# of unavailable users shown in list: " + unavailableUsers.length)
                } catch (error) {
                    // do nothing. just continue
                    console.error(error)
                }
                
                
            }).catch(error => {
                console.error(error);
            })
        }
    }

    return (
        <nav className="h-16 w-full bg-black px-9 sticky top-0 z-50">
            <div className="container mx-auto flex flex-row items-center h-full">
                <div className="flex w-6/12">
                    <Link to={{ pathname: '/' }}>
                        <img src={require('../../../images/logo-yellow.png').default} className="h-10 mr-7 cursor-pointer" alt="TradesLink"></img>
                    </Link>
                    <div className="rounded-md w-full flex bg-offWhite items-center pr-3.5">
                        <img src={require('../../../images/icons/misc/search.png').default} className="h-5 w-5 object-contain ml-3.5 mr-2" alt="Search"></img>
                        <input className="rounded-md w-full h-full outline-none bg-offWhite" value={search} onChange={(evt) => setSearch(evt.target.value)} placeholder="Search by any criteria" onKeyDown={(evt) => getResults(evt)}></input>
                    </div>
                </div>
                <div className="flex w-6/12 justify-end items-center">
                    <Link to={{ pathname: "/search"}}>
                        <img src={require('../../../images/icons/navigation/advanced-search.png').default} className="h-9 w-9 object-contain" alt="crews" />
                    </Link>

                    <Link to={{ pathname: "/conversations" }}>
                        <div className="relative">
                            {
                                unreadMessages ? <div className="unread-messages-bubble">{ unreadMessages > 9 ? '10+' : unreadMessages }</div> : null
                            }
                            
                            <img src={require('../../../images/icons/navigation/messages.png').default} className="h-9 w-9 object-contain mx-8" alt="messages" />
                        </div>
                        
                    </Link>

                    <div className="dropdown inline-block relative menu-icon">
                        <img src={require('../../../images/icons/navigation/menu.png').default} className="h-9 w-9 object-contain" alt="menu" />
                        <NavigationDropDown></NavigationDropDown>
                    </div>

                </div>
            </div>

        </nav>
    )

}

export default Navigation