import React from 'react'
import ProfileInformation from './partials/ProfileInformation'
import ProfileStats from './partials/ProfileStats'

const ProfileMeta = ({profile, userId}) => {

    return (
        <div>
            <div className="flex flex-row px-8 py-4 justify-between">
                <ProfileInformation user={profile.user}></ProfileInformation>
                <ProfileStats profile={profile} userId={userId}></ProfileStats>
            </div>
            <p className="px-8 pb-4">{ profile.user.bio }</p>
        </div>

    )

}

export default ProfileMeta