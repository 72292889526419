import React, { useEffect, useRef, useState } from 'react'
import SearchResultsRow from './SearchResultsRow'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const ResultsList = () => {

    let advancedSearch = useSelector(state => state.advancedSearch);
    const dispatch = useDispatch();
    const loader = useRef(null);

    const [matchFirst, setMatchFirst] = useState([])

    useEffect(() => {
        // console.log("Resorting list")
        setMatchFirst(advancedSearch.results.map( user => <SearchResultsRow user={user} key={user.id}></SearchResultsRow>))
    }, [advancedSearch])

    useEffect(() => {
        var options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0
        }
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advancedSearch.total_pages])

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            dispatch({
                type: 'INCREMENT_PAGE',
            })
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    return (
        <>
            <div className="flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <p className="w-4/12"></p>
                    <p className="w-1/12 urbane-medium text-center lg:text-sm text-xs">Availability</p>
                    <p className="w-1/12 urbane-medium text-center lg:text-sm text-xs">Location</p>
                    <p className="w-1/12 urbane-medium text-center lg:text-sm text-xs">Trade</p>
                    <p className="w-1/12 urbane-medium text-center lg:text-sm text-xs">Level of Achievement</p>
                    <p className="w-1/12 urbane-medium text-center lg:text-sm text-xs">Safety Tickets</p>
                    <p className="w-1/12 urbane-medium text-center lg:text-sm text-xs">Skills</p>
                    <p className="w-2/12"></p>
                </div>
                {/* {tableRows} */}
                {matchFirst}
                {(advancedSearch.page !== 'N/A') &&
                    <div ref={loader}>
                        <div className="flex flex-row items-center justify-center">
                            {/* <p className="urbane-medium text-lg mr-4">Loading...</p> */}
                            <button className="text-large text-blue cursor-pointer" onClick={() => scrollToTop()}>back to top</button>
                            {/* <img alt="" className="animate-spin-slow h-6" src={require('../../../../images/icons/misc/loading.png').default} /> */}
                        </div>
                    </div>
                }
                {(advancedSearch.page === 'N/A') &&
                    <div className="flex flex-col justify-center items-center">
                        <p className="text-xl urbane-medium ">End Of Results</p>
                        <button className="text-large text-blue cursor-pointer" onClick={() => scrollToTop()}>back to top</button>
                    </div>
                }
            </div>
        </>
    )

}

export default ResultsList