import React from 'react'
import AdvancedSearchFiltersHeader from './AdvancedSearchFilters/AdvancedSearchFiltersHeader'
import AdvancedSearchFiltersMain from './AdvancedSearchFilters/AdvancedSearchFiltersMain'

const AdvancedSearchFilters = () => {

    return (
        <div className="p-5">
            <div className="bg-gray py-1 px-2">
                <h4 className="urbane-medium text-lg">TradesLink Access - Advanced Search</h4>
            </div>
            <AdvancedSearchFiltersHeader></AdvancedSearchFiltersHeader>
            <div className="bg-gray py-1 px-2 mt-5 mb-2">
                <h4 className="urbane-medium text-lg">Search for Trades by:</h4>
            </div>
            <AdvancedSearchFiltersMain></AdvancedSearchFiltersMain>
        </div>
    )

}

export default AdvancedSearchFilters