import Pusher from 'pusher-js';
import { key, cluster, appId, secret, encrypted } from '../pusher.json'
import { getChats, getMessages, getMessagesGreaterThanId } from './chat';
import { store } from '../store/store'

export const pusher = new Pusher(key, {
    cluster: cluster,
    appId,
    secret,
    key
    // encrypted,
    // auth: {
    //     headers: {
    //         'Authorization': 'Bearer ' + store.getState().loggedInUser.bearer_token
    //     }
    // }
});

export const setupChat = () => {
    const channel = pusher.subscribe(`chats.${store.getState().conversations.token}`);

    updateChannels();

    channel.bind('new-chat-created', updateChannels);

    // channel.bind('new-message', data => {
    //     updateChannels()
    // });
}

export const updateChannels = () => {
    getChats().then(response => {
        store.dispatch({ type: 'SET_CHATS', chats: response.data.chats });
    }).catch(error => {
        console.error(error);
    })
}

export const updateMessages = (evt) => {
    // for the currently highlighted convo
    let channelId = store.getState().selectedConversation.channelId;
    if (evt.chat.id === channelId) {
        
        let messages = store.getState().selectedConversation.messages;
        let latestDate = null;
        if(messages.length > 0){
            latestDate = messages[messages.length - 1].created_at;
        }
        getMessages(channelId, latestDate).then(response => {
            store.dispatch({ type: 'PUSH_NEW_MESSAGES', messages: response.data.messages });
            store.dispatch({ type: 'PUSH_NEW_MESSAGES_TO_CHAT', chat_id: response.data.messages.chat_id, messages: response.data.messages })
        }).catch(error => {
            console.error(error);
        })
    } else {

        const chat = store.getState().conversations.chats.find(ch => ch.id === evt.chat.id)
        if (chat.messages.length) {
            const id = chat.messages[0].id
            setTimeout(() => {
                getMessagesGreaterThanId(evt.chat.id, id).then(response => {
                    store.dispatch({ type: 'PUSH_NEW_MESSAGES_TO_CHAT', chat_id: response.data.messages.chat_id, messages: response.data.messages })
                })
            }, 1000)   
        }      
    }
}
