import React from 'react'

const ChatListHeader = ({ search, setSearch }) => {

    return (
        <>
            <h3 className="text-2xl urbane-demibold">Conversations</h3>
            <div className="w-full flex flex-row items-center bg-white py-3 rounded-md border mt-3">
                <img alt="" src={require('../../../../../images/icons/misc/search.png').default} className="h-5 w-5 object-contain ml-3.5 mr-2"></img>
                <input placeholder="Search Conversations" className="w-full h-6 outline-none flex-1 pr-2" value={search} onChange={(evt) => setSearch(evt.target.value)}></input>
            </div>
        </>
    )

}

export default ChatListHeader