import React from 'react'
import { Link } from 'react-router-dom';
import { formatTrades } from '../../../../helpers/profileHelpers';

const  VouchItem = ({ vouch, toggleOverlay }) => {
    if(!vouch || !vouch.vouched_by){
        return false;
    }

    return (
        <div className="mb-4 flex flex-row items-start justify-between bg-white rounded-md p-3 shadow-md">
            <div className="flex flex-row items-center">
            {!vouch.vouched_by.active_profile_picture
                    ? <img src={require('../../../../images/icons/misc/avatar.png').default} className="rounded-full h-16 w-16 mr-2 object-contain" alt="avatar" />
                    : <img src={vouch.vouched_by.active_profile_picture.thumbnail_url} className="rounded-full h-16 w-16 mr-2 object-contain" alt="avatar" />
                }
                <div className="">
                    <p className="urbane-medium text-lg">{vouch.vouched_by.full_name && vouch.vouched_by.full_name}</p>
                    <p className="text-sm">{vouch.vouched_by.trade && formatTrades(vouch.vouched_by.trade) }</p>
                </div>
            </div>
            <div className="flex-1 px-6">
                <p >{vouch.comment && vouch.comment}</p>
                <Link to={{pathname:`/profile/${vouch.vouched_by.id}`}} onClick={() => document.querySelector('body').style.overflowY = 'auto'}>
                    <p className="text-right cursor-pointer text-blue mt-4">View Profile</p>
                </Link>
                
            </div>

        </div>
    )

}

export default VouchItem