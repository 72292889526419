import React from 'react'
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ( { children, ...rest } ) => {

    const loggedInUser = useSelector(state => state.loggedInUser.user);

    return (
        <Route {...rest} render={({ location }) => {
          return loggedInUser !== null
            ? children
            : <Redirect to={{
                pathname: '/login',
                state: { from: location }
              }} />
        }} />
      )

}

export default PrivateRoute