import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import authenticatedAxios from '../../../../../data/axiosWithAuth'
import { createChat } from '../../../../../data/chat'
import { updateChannels } from '../../../../../data/pusher'
import Confirm from '../../../../Utilities/Modals/Confirm'

const MessageUser = ({ user, userId }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [canMessage, setCanMessage] = useState(false);
    const availability = user.available_for_work;
    const [confirmMessage, setConfirmMessage] = useState()
    const [showConfirmDialogue, setShowConfirmDialogue] = useState(false)
    const [loadingMessaging, setLoadingMessaging] = useState(false)
    
    const handleChatCreate = async () => {
        setLoadingMessaging(true)
        // check to see if the chat already exists
        const { data } = await authenticatedAxios.get(`/chats/check/${userId}`)
        let message = null
        if (data.chat) {
            const companySubscriber = data.chat.subscribers.find(sub => sub.access_company_id)
            
            if (companySubscriber) {
                if (companySubscriber.user) {
                    message = `It appears as though ${companySubscriber.user.full_name} has created a chat with this individual. Would you still like to reach out?`
                }
                else {
                    message = `It appears that a chat has already been opened with this user. Would you still like to reach out?`
                }
            }
        }

        if (! message) {
            confirmChatCreate()

            return
        }
        
        setConfirmMessage(message)
        setShowConfirmDialogue(true)
    }

    const confirmChatCreate = () => {
        createChat(userId).then(response => {
            console.log(response)
            dispatch({
                type: 'SET_SELECTED_CONVERSATION',
                channelId: response.data.chat.id,
                user: user
            })
            updateChannels();
            history.push('/conversations');
        }).catch(error => {
            if (error.response.status === 409) {
                dispatch({
                    type: 'SET_SELECTED_CONVERSATION',
                    channelId: error.response.data.id,
                    user: user
                })
                updateChannels();
                history.push('/conversations');
            }
        })
    }

    useEffect(() => {
        setCanMessage(false);
        if (user.available_for_work == null && user.available_for_work_date == null) {
            setCanMessage(true)
        } else if (availability === 0 || availability === false || availability === null) {
            setCanMessage(false);
        } else if (availability === 1 && user.available_for_work_date) {
            let date = new Date(user.available_for_work_date.split(' ')[0]);
            let today = new Date();
            if (date === today || date < today) {
                setCanMessage(true);
            } else {
                setCanMessage(false);
            }
        } else if (availability) {
            setCanMessage(true);
        }
    }, [availability, user.available_for_work_date])

    return (
        <>
            {(canMessage && ! loadingMessaging) ? 
                <div className="rounded py-3 px-5 bg-blue flex-1 mb-2 flex justify-center cursor-pointer" onClick={() => handleChatCreate()}>
                    <p className="urbane-medium whitespace-nowrap text-white">Message {user.full_name}</p>
                </div> :

                <div className="rounded py-3 px-5 bg-blue flex-1 mb-2 flex justify-center cursor-pointer">
                    <p className="urbane-medium whitespace-nowrap text-white">Loading...</p>
                </div>
            }
            { showConfirmDialogue && 
                <Confirm 
                    message={confirmMessage} 
                    yes={confirmChatCreate }
                    no={() => {
                        setShowConfirmDialogue(false) 
                        setLoadingMessaging(false)
                    }}
                    dismiss={() => {
                        setShowConfirmDialogue(false) 
                        setLoadingMessaging(false)
                    }} /> }
        </>
    )

}

export default MessageUser