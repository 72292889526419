import authenticatedAxios from '../axiosWithAuth'


export const getGenericSearchResults = (search, page) => {
    return authenticatedAxios.get(`/search?q=${search}&page=${page}`)
}

export const getAdvancedSearchResults = (availability, locations, tradesArray, tickets, skills, page) => {
    return authenticatedAxios.post(`/advanced-search?page=${page}`,{
        location: locations,
        trades: tradesArray,
        tickets: tickets,
        skills: skills,
        availability: availability
    })
}