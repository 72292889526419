import React from 'react'
import Navigation from '../Utilities/Navigation/Navigation'
import AdvancedSearchFilters from './partials/AdvancedSearchFilters'
import AdvancedSearchHeader from './partials/AdvancedSearchHeader'
const AdvancedSearch = () => {

    return (
        <>
            <Navigation></Navigation>
            <main className="container mx-auto bg-offWhite rounded-md mt-8 shadow-lg pb-24">
                <AdvancedSearchHeader></AdvancedSearchHeader>
                <AdvancedSearchFilters></AdvancedSearchFilters>
            </main>
        </>
    )

}

export default AdvancedSearch