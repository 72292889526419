import ButtonBlue from "../Buttons/ButtonBlue"
import ButtonRed from "../Buttons/ButtonRed"

const Confirm = ({ yes, no, dismiss, message}) => {
    return (
        <>
        <div className="absolute w-full h-full left-0 top-0 flex flex-row justify-center">
            <div className="fixed h-full w-full flex flex-row bg-darkGray bg-opacity-40 justify-center align-middle z-50" onClick={dismiss}>
               
            </div>

            <div className="fixed top-1/4 rounded bg-white w-1/2 flex flex-col p-5 z-50 shadow-2xl">
                <p className="text-center mb-5">{ message }</p>

                <div className="flex flex-row justify-around">
                    <ButtonRed label="No" clickAction={no} />
                    <ButtonBlue label="Yes" clickAction={yes} />
                </div>

            </div>          

        </div>
        </>
    )
}

export default Confirm