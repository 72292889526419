import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import useRemoveTradeFromSearch from '../../../../../hooks/search/trades/useRemoveTradeFromSearch';
import useSetTradesSearch from '../../../../../hooks/search/trades/useSetTradesSearch';
import useRemoveFilter from '../../../../../hooks/search/useRemoveFilter';
import useTradeFilters from '../../../../../hooks/search/useTradeFilters';
import useGetTradeLevelSuggestions from '../../../../../hooks/useGetTradeLevelSuggestions';
import useGetTradesSuggestions from '../../../../../hooks/useGetTradesSuggestions';
import AppliedFilterItem from '../../../../Utilities/AppliedFilterItem';
import SuggestionDropdown from './SuggestionDropDown';

const TradeFilter = () => {

    const [showSuggestions, setShowSuggestions] = useState(false);
    const { trade, setTrade, error, errorMessage, setFilter, levelOfAchievement, setLevelOfAchievement, clearForm } = useTradeFilters()
    const { levels } = useGetTradeLevelSuggestions()
    const [ trades ] = useGetTradesSuggestions(trade)

    const setTradesSearch = useSetTradesSearch()
    const tradesList = useSelector(state => state.advancedSearch.trades)
    const removeTrade = useRemoveTradeFromSearch()

    const achievementLevels = levels.map(level => (
        <option value={level.name} key={level.id}>{level.name}</option>
    ))

    const appliedFilters = tradesList.map(trade => {
        
        return <AppliedFilterItem filter={{...trade, type: 'TRADE'}} key={trade.name + trade.level} remove={() => {
            removeTrade(trade)
        }}></AppliedFilterItem>
    })

    return (
        <div className="bg-white rounded p-4 shadow mb-4">
            <div className="flex flex-row justify-between">
                <h5 className="urbane-medium text-lg">Trade &amp; Level of Achievement</h5>
                <button className="apply-filter-button" onClick={() => {
                    setTradesSearch(trade, levelOfAchievement)
                    clearForm()
                }}>Apply Filter</button>
            </div>
            <div className="flex flex-col">
                <div className="relative inline-block">
                    <input placeholder="Specify which type of trade" className="filter-input" value={trade} 
                    onChange={(event) => setTrade(event.target.value)} 
                    onFocus={() => setShowSuggestions(true)} 
                    onBlur={() => setTimeout(() => { setShowSuggestions(false) }, [200])}
                    onKeyDown={(evt) => {
                        if(evt.keyCode === 13){ 
                            setFilter();
                            evt.target.blur();
                        }
                    }}
                    ></input>
                    <SuggestionDropdown items={trades} visible={showSuggestions} setItem={trade => {
                        setTrade(trade)
                        setShowSuggestions(false)
                    }}></SuggestionDropdown>
                </div>

                <select 
                    className="w-1/4 h-12 mr-4 mb-4 rounded border-2 focus:outline-none focus:ring focus:border-blue" 
                    onChange={(evt) => {
                        setLevelOfAchievement(evt.target.value)
                    }} 
                    value={levelOfAchievement}>

                    <option value='' className="h-4">Not Applicable</option>
                    {achievementLevels}
                </select>
                {error && <p className="text-red -mt-2 text-sm ml-2">{errorMessage}</p>}
            </div>
            <div className="flex flex-row flex-wrap">
                {appliedFilters}
            </div>
        </div>
    )
}
export default TradeFilter