import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AppliedFilterItem from './AppliedFilterItem'

const AppliedSearchFilters = () => {

    const [appliedFilters, setAppliedFilters] = useState([]);
    const dispatch = useDispatch()
    let locationsFilter = useSelector(state => state.advancedSearch.locations);
    let tradesFilter = useSelector(state => state.advancedSearch.trades);
    let availabilityFilter = useSelector(state => state.advancedSearch.availability);
    let ticketsFilter = useSelector(state => state.advancedSearch.tickets);
    let skillsFilter = useSelector(state => state.advancedSearch.skills);

    useEffect(() => {
        let filters = [];
        if(availabilityFilter){
            filters.push({
                name: availabilityFilter,
                type: 'AVAILABILITY'
            })
        } else {
            filters.push({
                name: 'Currently Available',
                type: 'AVAILABILITY'
            })
        }

        if(locationsFilter.length > 0){
            locationsFilter.forEach(location => {
                filters.push({
                    name: location,
                    type: 'LOCATION'
                })
            });
        }
        if(tradesFilter.length > 0){
            tradesFilter.forEach(trade => {
                filters.push({
                    name: trade.name,
                    level: trade.level,
                    type: 'TRADE'
                })
            });
        }
        if(ticketsFilter.length > 0){
            ticketsFilter.forEach(ticket => {
                filters.push({
                    name: ticket,
                    type: 'TICKET'
                })
            })
        }

        if(skillsFilter.length > 0){
            skillsFilter.forEach(skill => {
                filters.push({
                    name: skill,
                    type: 'SKILL'
                })
            })
        }
        setAppliedFilters(filters);
    }, [locationsFilter, tradesFilter, availabilityFilter, ticketsFilter, skillsFilter])


    const removeFilter = (filter) => {
        dispatch({
            type: `REMOVE_${filter.type}_SEARCH`, 
            item: filter,
        })
    }

    const appliedFiltersList = appliedFilters.map((filter, index) => (
        <AppliedFilterItem filter={filter} key={index.toString()} remove={removeFilter}></AppliedFilterItem>
    ))

    return (
        <div className="">
            <h4 className="text-lg urbane-medium mb-3">Applied Filters</h4>
            <div className="flex flex-row flex-wrap">
                {appliedFiltersList}
        </div>
        </div>
    )

}

export default AppliedSearchFilters