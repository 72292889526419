import { useEffect, useState } from "react"

export default function useUserInitials(user) {
    const [initials, setInitials] = useState('')

    useEffect(() => {
        if (user) {
            setInitials(String(user.first_name[0] + user.last_name[0]).toUpperCase())
        }
    }, [user])

    return { initials }
}