import React from 'react'
import { logout } from '../../../data/Authentication/authentication'


const NavigationDropDown = () => {

    return (
        <ul className="dropdown-menu absolute bg-white shadow-lg w-48 rounded-md right-0 p-4 hidden">
            <li className="hover:bg-blue hover:bg-opacity-30' cursor-pointer flex flex-row w-full p-2 rounded group logout-item" onClick={() => logout()}>
                <img src={require('../../../images/icons/navigation/logout.png').default} className="h-6 block logout-icon" alt="logout"/>
                <img src={require('../../../images/icons/navigation/logout-white.png').default} className="h-6 hidden logout-icon-white" alt="logout"/>
                <p className="ml-4 group-hover:text-white urbane-medium">Sign out</p>
            </li>
        </ul> 
    )

}

export default NavigationDropDown