import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import MatchIndicator from '../MatchIndicator';

const SkillsMatch = ({user}) => {

    const skills = useSelector(state => state.advancedSearch.skills);
    const search = useSelector(state => state.advancedSearch.search)
    const [matches, setMatches] = useState(0);
    const searchType = useSelector(state => state.advancedSearch.search_type);

    useEffect(() => {
        let foundMatches = 0;
        if (searchType === 'generic') {
            user.skills.forEach(skill => {
                if (skill.name.toLowerCase().includes(search.toLowerCase())) { 
                       foundMatches++;
                }
            })
        } else {
            skills.forEach(ticket => {
                user.skills.forEach(skill => {
                    if (skill.name.toLowerCase().includes(ticket.toLowerCase())) {
                           foundMatches++;
                    }
                })
            })
        }
        setMatches(foundMatches);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skills, user, search, searchType])


    const match = () => {
        if(searchType === 'generic'){
            if(matches > 0){
                return 'match'
            } else {
                return ''
            }
        } else {
            if(skills.length > 0){
                if(matches >= skills.length){
                    return 'match'
                } else if(matches > 0) {
                    return 'partial_match'
                } else {
                    return 'no_match'
                }
            } else {
                return '';
            }
        }
    }
    
    return (
        <MatchIndicator match={match()}></MatchIndicator>
        
    )

}

export default SkillsMatch