import React, { useState } from 'react'
import VouchesOverlay from '../../Vouches/VouchesOverlay'
import MessageUser from './MessageUser';
import WorkAvailability from './WorkAvailability';

const ProfileStats = ({ profile, userId }) => {

    const [visible, setVisible] = useState(false);

    const toggleOverlay = () => {
        let body = document.querySelector('body');
        if (visible) {
            setVisible(false);
            body.style.overflowY = 'auto';
        } else {
            setVisible(true);
            body.style.overflow = 'hidden';
        }
    }



    return (
        <div>
            <MessageUser user={profile.user} userId={userId}></MessageUser>
            <WorkAvailability user={profile.user}></WorkAvailability>
            <p className={`text-center urbane-medium py-3 ${profile.vouches.length > 0 && 'text-blue cursor-pointer' }`} onClick={() => profile.vouches.length > 0 ? toggleOverlay() : null }>{ profile.vouches.length > 1 || profile.vouches.length === 0 ? profile.vouches.length + " Vouches" : profile.vouches.length + ' Vouch'} </p>
            {visible && <VouchesOverlay toggleOverlay={toggleOverlay} vouches={profile.vouches}></VouchesOverlay>}
        </div>
    )

}

export default ProfileStats