import React from 'react'
import VouchItem from './VouchItem'

const VouchesOverlay = ({ visible, toggleOverlay, vouches }) => {

    const vouchesList = vouches.map((vouch) => (
        <VouchItem vouch={vouch} toggleOverlay={toggleOverlay}></VouchItem>
    ))

    return (
        <div className="fixed w-screen h-screen overflow-hidden top-0 bottom-0 right-0 left-0 bg-gray bg-opacity-50 flex flex-row justify-center items-center">
            <div className="rounded-md w-5/6 h-5/6 bg-offWhite shadow overflow-hidden absolute">
                <div className="bg-gray p-3 flex flex-row justify-between">
                    <h5 className="text-xl urbane-demibold">Vouches for by</h5>
                    <p className="text-xl urbane-demibold cursor-pointer" onClick={() => toggleOverlay()}>X</p>
                </div>
                <div className="p-8 overflow-y-auto vouches-scroll">
                    {vouchesList}
                </div>
            </div>
        </div>

    )

}

export default VouchesOverlay