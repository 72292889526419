import React, { useEffect, useState } from 'react'

const MatchIndicator = ({ match, title = '' }) => {

    const [color, setColor] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        if (match === 'match') {
            setColor('bg-green');
            setText('Match');
        } else if (match === 'partial_match') {
            setColor('bg-yellow');
            setText('Partial');
        } else if (match === 'no_match') {
            setColor('bg-red');
            setText('No Match');
        } else {
            setColor('');
            setText('-');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match]);

    return (
        <div className={`h-10 w-16 rounded flex flex-row items-center justify-center mx-auto ${color}`} title={title}>
            <p className="lg:text-sm text-center urbane-medium text-xs mx-1">{text}</p>
        </div>
    )
}

export default MatchIndicator