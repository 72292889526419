import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router';
import useUserInitials from '../../../../../hooks/users/useUserInitials';

const ConversationHeader = () => {

    const user = useSelector(state => state.selectedConversation.user);
    const history = useHistory();
    const { initials } = useUserInitials(user)

    return (
        <>
            {user &&
                <div className="flex flex-row p-4 shadow-lg justify-between items-center">
                    <div className="flex flex-row items-center">
                        {user.active_profile_picture
                            ?
                            <img alt="" src={user.active_profile_picture.thumbnail_url} className="rounded-full mr-4 h-14" />
                            :
                            <div className="rounded-full mr-4 h-14 w-14 flex justify-center items-center bg-blue">
                                <p className="urbane-medium text-white">{initials}</p>
                            </div>
                        }


                        <div className="flex flex-col">
                            <p className="urbane-demibold text-lg">{user.full_name}</p>
                            <p className="text-sm">{user.trade}</p>
                        </div>
                    </div>
                    <p className="text-blue text-medium cursor-pointer" onClick={() => history.push(`/profile/${user.id}`)}>View Profile</p>
                </div>
            }
        </>
    )

}

export default ConversationHeader