const INITIAL_STATE = {
    user: null,
    bearer_token: null,
}

const LoggedInUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            let { user } = state

            user = action.payload

            return { ...state, user }

        case 'SET_BEARER_TOKEN':
            let { bearer_token } = state

            bearer_token = action.payload

            return { ...state, bearer_token }

        case 'SET_CURRENT_USER_TO_NULL':
            state.user = null

            return { ...state }
        
        default:
            return state
    }
}

export default LoggedInUserReducer