import React from 'react'
import EducationItem from './EducationItem'

const Education = ({ education, user }) => {

    const educationList = education.map((item) => (
        <EducationItem education={item} key={item.id}></EducationItem>
    ))

    return (
        <div className="px-3">
            <div className="bg-gray py-1 px-2">
                <h4 className="urbane-medium text-lg text-center">Education</h4>
            </div>
            <div className="flex flex-row justify-center flex-wrap p-8">
                {education.length > 0
                    ? educationList
                    : <p>{user.full_name} hasnt added their education yet</p>
                }
            </div>

        </div>
    )

}

export default Education