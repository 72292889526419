import React from 'react'

const AppliedFilterItem = ({ filter, remove }) => {

    const filterTypeText = () => {
        return filter.type.charAt(0).toUpperCase() + filter.type.slice(1).toLowerCase();
    }

    return (
        <div className="flex flex-row items-center justify-between p-1.5 border-blue border rounded mr-2 mb-2 bg-white shadow">
            { filter.level
                ? <p className="text-blue urbane-medium">{filterTypeText() + ': ' + filter.level + " " + filter.name}</p>
                : <p className="text-blue urbane-medium">{filterTypeText() + ': ' + filter.name}</p>
            }
            {filter.name !== 'Currently Available' && <p className="pl-4 pr-2 text-blue urbane-medium text-md cursor-pointer" onClick={() => remove(filter)}>x</p>}
        </div>
    )

}

export default AppliedFilterItem