
import axios from 'axios'
import { API_URL } from '../../environment'

export const forgotPassword = (email) => {
    return axios.post(`${API_URL}auth/forgot-password`, {
        email: email,
    })
}

export const resetPassword = (password, confirmPassword, token) => {
    return axios.post(`${API_URL}auth/reset-password`, {
        password: password,
        token: token,
        confirm_password:confirmPassword
    })
}

export const validateResetToken = (token) => {
    return axios.post(`${API_URL}auth/reset-password/validate`, {
        token: token
    })
}

