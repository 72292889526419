import React, { useEffect } from 'react'
import {
    Switch,
    Route,
    useLocation,
} from "react-router-dom";
import AdvancedSearch from './Components/AdvancedSearch/AdvancedSearch'
import AdvancedSearchResults from './Components/AdvancedSearchResults/AdvancedSearchResults';
import Home from './Components/Home.jsx/Home';
import Login from './Components/Login/Login'
import Messaging from './Components/Conversations/Conversations';
import UserProfile from './Components/UserProfile/UserProfile';
import PrivateRoute from './Components/Utilities/PrivateRoute';
import AccountActivation from './Components/AccountActivation/AccountActivation';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
// import { useSelector } from 'react-redux';
// import { setupChat } from './data/pusher';
import NoMatch from './Components/NoMatch';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import usePusher from './hooks/usePusher';


const Routes = () => {

    const location = useLocation();

    const { setupPusher, user } = usePusher()

    useEffect(() => {
        if (user) {
            setupPusher()
        }

    }, [user])

    return (

        <TransitionGroup>
            <CSSTransition
                key={location.pathname}
                classNames="fade"
                exit={false}
                timeout={500}
            >
                <div className="h-screen">
                    <Switch location={location}>
                        <Route exact path="/login">
                            <Login></Login>
                        </Route>
                        <Route exact path="/activation">
                            <AccountActivation></AccountActivation>
                        </Route>
                        <Route exact path="/forgot-password">
                            <ForgotPassword></ForgotPassword>
                        </Route>
                        <Route exact path="/reset-password">
                            <ResetPassword></ResetPassword>
                        </Route>
                        <PrivateRoute exact path="/">
                            {/* <Home></Home> */}
                            <AdvancedSearch></AdvancedSearch>
                        </PrivateRoute>
                        <PrivateRoute exact path="/search">
                            <AdvancedSearch></AdvancedSearch>
                        </PrivateRoute>
                        <PrivateRoute exact path="/results">
                            <AdvancedSearchResults></AdvancedSearchResults>
                        </PrivateRoute>
                        <PrivateRoute exact path="/profile/:id">
                            <UserProfile></UserProfile>
                        </PrivateRoute>
                        <PrivateRoute exact path="/conversations">
                            <Messaging></Messaging>
                        </PrivateRoute>
                        <PrivateRoute exact path="/conversations/:chat_id">
                            <Messaging></Messaging>
                        </PrivateRoute>
                        <Route path="*">
                            <NoMatch />
                        </Route>
                    </Switch>
                </div>
            </CSSTransition>
        </TransitionGroup>
    )

}

export default Routes