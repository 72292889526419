import axios from 'axios'
import authenticatedAxios from '../axiosWithAuth'
import { store } from '../../store/store'
import { API_URL } from '../../environment'

export const setBearerToken = () => {
    const promise = new Promise((resolve, reject) => {
        let bearer_token = localStorage.getItem('bearer_token');
        if (bearer_token) {
            authenticatedAxios.get('/auth/company').then(response => {
                store.dispatch({ type: 'SET_BEARER_TOKEN', payload: bearer_token });
                store.dispatch({ type: 'SET_CURRENT_USER', payload: response.data.company });
                store.dispatch({ type: 'SET_CHAT_TOKEN', token: response.data.company.chat_token})
                resolve();
            }).catch(error => {
                console.error(error);
                resolve();
            })
        } else {
            resolve();
        }

    })
    return promise
}

export const login = (email, password) => {
    return axios.post(`${API_URL}auth/login/company`, {
        email: email,
        password: password
    })
}

export const logout = () => {
    authenticatedAxios.delete('auth/logout/company').then(response => {
        store.dispatch({type: 'SET_CURRENT_USER_TO_NULL'});
        store.dispatch({ type: 'CLEAR_AUTHENTICATED_TRADESLINK_USER_DATA' })
        localStorage.clear();
    });
}





