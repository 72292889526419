import React from 'react'
import ConversationBody from './partials.jsx/ConversationBody/ConversationBody'
import ConversationFooter from './partials.jsx/ConversationFooter/ConversationFooter'
import ConversationHeader from './partials.jsx/ConversationHeader'

const Chat = () => {

    return (
        
        <div className="h-full rounded-md shadow-lg bg-offWhite w-2/3 ml-2 overflow-hidden flex flex-col">
            <ConversationHeader></ConversationHeader>
            <ConversationBody></ConversationBody>
            <ConversationFooter></ConversationFooter>
        </div>
    )

}

export default Chat