
const months = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];


export const getMonthDayYearFormat = (value) => {
    let date = new Date(value);
    let month = date.getUTCMonth();
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();

    return `${months[month]} ${day}, ${year}`
}

export const getHourMinuteFormat = (value) => {
    let date = new Date(value);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


