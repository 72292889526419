import { store } from '../store/store';
import authenticatedAxios from './axiosWithAuth'

const options = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
    }
}


export const createChat = (userId) => {
    const promise = new Promise(async (resolve, reject) =>{
        const data = {}

        try {
            // check local storage for a tradeslink user
            if (localStorage.getItem('authenticated_tradeslink_user')) {
                data.authenticated_tradeslink_user = JSON.parse(localStorage.getItem('authenticated_tradeslink_user')).id
            }

            const response = await authenticatedAxios.post(`/chats/${userId}`, data);

            resolve(response)
        } catch (error) {
            reject(error)
        }
        
    })

    return promise    
}

export const getChats = () => {
    return authenticatedAxios.get('/chats');
}

export const getMessages = (channelId, latestDate = null) => {
    let queryString = ''

    if (latestDate) {
        queryString = `?messages_newer_than=${latestDate}`
    }
    
    return authenticatedAxios.get(`chats/${channelId}${queryString}`);
}

export const getMessagesGreaterThanId = (chat_id, message_id) => {
    return authenticatedAxios.get(`chats/${chat_id}?message_id_greater_than=${message_id}`)
}

export const sendMessage = (channelId, message) => {
    return authenticatedAxios.post(`chats/${channelId}/messages`, {
        message: message
    });
}

export const sendMediaMessage = (channelId, message, media) => {
    const formData = new FormData();
    formData.append('media', media);
    formData.append('message', message);
    return authenticatedAxios.post(`chats/${channelId}/messages/media`, formData, options)
}

export const markChatMessagesRead = (chat_id) => {
    const promise = new Promise((resolve, reject) => {
        try {
            // This will mark all messages from a user in the specified chat as read/seen
            const response = authenticatedAxios.post(`chats/${chat_id}/messages/mark-read`)
            
            store.dispatch({ type: 'MARK_CHAT_MESSAGES_READ', chat_id: chat_id })
            resolve(response)
        } catch (error) {
            reject(error)
        }
    })

    return promise
    
}