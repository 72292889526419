import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import useSetTicketsSearch from '../../../../../hooks/search/tickets/useSetTicketsSearch';
import useTicketsSuggestions from '../../../../../hooks/search/tickets/useTicketsSuggestions';
import useRemoveFilter from '../../../../../hooks/search/useRemoveFilter';
import AppliedFilterItem from '../../../../AdvancedSearchResults/partials/SearchResults/AppliedFilters/AppliedFilterItem';
import SuggestionDropdown from './SuggestionDropDown';

const TicketsFilter = () => {
    const inputRef = useRef()
    const [ticket, setTicket] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let ticketFilters = useSelector(state => state.advancedSearch.tickets);

    const removeFilter = useRemoveFilter()
    const setTicketsSearch = useSetTicketsSearch()
    const { tickets } = useTicketsSuggestions(ticket)

    useEffect(() => {
        setError(false);
    }, [ticketFilters])

    const setFilter = (name) => {
        if(name){
            if(ticketFilters.length > 2){
                setErrorMessage('A maximum of 3 tickets can be added');
                setError(true);
            } else if(ticketFilters.includes(name)) {
                setErrorMessage('Ticket already added');
                setError(true);
            } else {
                setTicketsSearch(name)
                setTicket('');
            }
        }
    }

    const appliedFilters = ticketFilters.map(ticket => {
       let item = {
            name: ticket,
            type: 'TICKET',
        };
         return <AppliedFilterItem filter={item} remove={removeFilter} key={item.name}></AppliedFilterItem>
    })



    return (
        <div className="bg-white rounded p-4 shadow mb-4">
            <div className="flex flex-row justify-between items-center">
                <h5 className="urbane-medium text-lg">Safety Tickets</h5>
                <button className="apply-filter-button" onClick={() => setFilter(inputRef.current.value)}>Apply Filter</button>
            </div>
            <div className="">
                <p className="mb-2">Input all relevant safety tickets</p>
                <div className="relative inline-block w-full">
                    <input placeholder="Type in safety tickets" className="filter-input" value={ticket} 
                    ref={inputRef}
                    onChange={(evt) => setTicket(evt.target.value)}
                    onFocus={() => setShowSuggestions(true)}
                    onBlur={() => setTimeout(() => {setShowSuggestions(false)}, [200])}
                    onKeyDown={(evt) => {
                        if(evt.keyCode === 13){ 
                            setFilter(inputRef.current.value);
                            evt.target.blur();
                        }
                    }}
                    ></input>
                    <SuggestionDropdown items={tickets} visible={showSuggestions} setItem={setFilter}></SuggestionDropdown>
                </div>
                
                <div className="flex flex-row flex-wrap">
                    {appliedFilters}
                </div>
            </div>
            {error && <p className="text-red -mt-2 text-sm ml-2">{errorMessage}</p>}
        </div>
    )

}

export default TicketsFilter