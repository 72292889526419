import axios from 'axios'
import { API_URL } from '../../environment'

export const checkToken = (token) => {
    return axios.get(`${API_URL}accounts/activate/check-token?token=${token}`,)
}

export const activateAccount = (password, confirmPassword, companyId, token) => {
    return axios.post(`${API_URL}accounts/activate/${companyId}`, {
        password: password,
        token: token,
        confirm_password: confirmPassword
    })
}


