import React, { useState } from 'react'
import { getHourMinuteFormat, getMonthDayYearFormat } from '../../../../../../../helpers/dateTimeHelpers'
import ViewMessageMedia from './ViewMessageMedia';

const MessageBubble = ({ message }) => {

    const [visible, setVisible] = useState(false);

    return (
        <div className="p-2">
            <div className={`flex ${message.subscriber.access_company_id !== null ? 'justify-end' : 'justify-start'}`}>
                <div className={`rounded-2xl py-1.5 px-3 ${message.subscriber.access_company_id !== null ? 'bg-blue text-white' : 'bg-gray'}`}>
                    <p className={`text-sm ${message.subscriber.access_company_id !== null && 'text-white'}`}>{message.message && message.message}</p>
                    {message.file_url &&
                        <>
                            <img alt="" src={message.file_url} className="h-56 w-48 object-contain cursor-pointer" onClick={() => setVisible(true)}></img>
                            {visible && <ViewMessageMedia visible={visible} setVisible={setVisible} url={message.file_url}></ViewMessageMedia>}
                        </>
                    }
                </div>
            </div>
            <p className={`ml-4 my-1.5 text-xs w-full urbane-extralight ${message.subscriber.access_company_id !== null ? 'text-right' : 'text-left'}`}>{getMonthDayYearFormat(message.created_at) + ' ' + getHourMinuteFormat(message.created_at)}</p>

        </div>
    )

}

export default MessageBubble 