import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getChats } from '../../data/chat'
import Navigation from '../Utilities/Navigation/Navigation'
import Conversation from './partials/Conversation/Conversation'
import ConversationList from './partials/ConversationList/ConversationList'

const Messaging = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        getChats()
            .then(response => {
                dispatch({ type: 'SET_CHATS', chats: response.data.chats})
            })
            .catch(error => console.error(error))
    }, [])

    return (
        <>
            <Navigation></Navigation>
            <main className="container flex w-full messaging-container py-12 mx-auto" style={{ minHeight: '600px'}}>
                <ConversationList></ConversationList>
                <Conversation></Conversation>
            </main>
        </>
    )
}

export default Messaging