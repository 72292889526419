import React from 'react'
import ForgotPasswordForm from './ForgotPasswordForm'


const ForgotPassword = () => {

    return (
        <main className="h-screen w-screen flex flex-col justify-center items-center bg-login bg-cover"> 
            <ForgotPasswordForm></ForgotPasswordForm>
        </main>
    )

}

export default ForgotPassword