import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getAdvancedSearchResults } from '../../../../data/AdvancedSearch/search';
import AppliedSearchFilters from '../../../Utilities/AppliedSearchFilters'



const AdvancedSearchFiltersHeader = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [errors, setErrors] = useState([]);
    const advancedSearch = useSelector(state => state.advancedSearch);

    const checkValid = () => {
        let valid = true;
        let newErrors = [];
        if (advancedSearch.locations.length < 1) {
            newErrors.push('Add at least one location');
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    }

    const search = () => {
        if (checkValid()) {
            console.log("requesting users from backend")
            getAdvancedSearchResults(
                advancedSearch.availability, 
                advancedSearch.locations, 
                advancedSearch.trades, 
                advancedSearch.tickets, 
                advancedSearch.skills, 
                1
            ).then(response => {
                let users = response.data.results.data.map(user => {
                    const user_trades_array = user.trades.map(tr => tr.name)
                    return { ...user, user_trades_array: user_trades_array }
                }).sort((a, b) => {
                    const now = (new Date).getTime()
                    const available_date = Date.parse(b.available_for_work_date)
        
                    if (b.available_for_work_date == null && b.available_for_work == null) {
                        return 1
                    }
        
                    if (! b.available_for_work) return -1
        
                    if (b.is_available_for_work) {
                        return 1
                    }
        
                    if (b.available_for_work_date && advancedSearch.availability) {
                        const requestedAvailability = Date.parse(advancedSearch.availability)
                        
                        if (requestedAvailability > available_date) {
                            return 1
                        } 
                    }
        
                    if (b.available_for_work_date && now > available_date) {
                        return 1
                    }
        
                    return -1
                })

                dispatch({
                    type: 'SET_ADVANCED_SEARCH', 
                    results: users,
                    total_results: response.data.results.total,
                    total_pages: response.data.results.last_page,
                })
                dispatch({ type: 'SET_SEARCH_TYPE', search_type: 'advanced' });
                history.push('/results');
            }).catch(error => {
                console.error(error);
            })
        }
    }

    const errorsList = errors.map(error => (
        <li className="text-red">{error}</li>
    ))

    return (
        <div className="">
            <div className="flex flex-row px-6 py-4 items-center">
                <p className="mr-4">
                    You can use the options on this page to create a very specific search. Just fill and apply the filters you need for your current search and click on the search button to find your trades.
                </p>
                <button className="rounded bg-blue text-white py-1.5 px-6" onClick={() => search()}>Search</button>
            </div>
            {errors.length > 0 &&
                <div className="mb-8">
                    <h4 className="urbane-medium text-red">Please correct the following errors:</h4>
                    <ul className="pl-8 list-disc">
                        {errorsList}
                    </ul>

                </div>
            }
            <AppliedSearchFilters></AppliedSearchFilters>
        </div>
    )

}

export default AdvancedSearchFiltersHeader