import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { activateAccount, checkToken } from '../../data/Authentication/activation';
import AccountActivationConfirmation from './AccountActivationConfirmation';
import AccountActivationForm from './AccountActivationForm';

const AccountActivation = () => {

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('Loading...');
    const [error, setError] = useState(false);
    const [token, setToken] = useState(null);
    const [company, setCompany] = useState(null);
    const [complete, setComplete] = useState(false);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        let queryToken = query.get('token');

        checkToken(queryToken).then(response => {
            setToken(queryToken);
            setLoading(false);
            setMessage(response.data.msg);
            setTitle(response.data.title);
            setCompany(response.data.company);
            setError(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            setTitle('Error');
            setMessage(error.response.data.msg);
            setError(true);
        })
    }, [])

    const savePassword = (password, confirmPassword) => {
        activateAccount(password, confirmPassword, company.id, token).then(response => {
            setMessage(response.data.msg);
            setComplete(true);
        }).catch((error) => {
            setTitle('Error');
            setMessage(error.response.data.message);
            setError(true);
        })
    }


    return (
        <main className="h-screen w-screen flex flex-col justify-center items-center bg-login bg-cover">
            { !complete
                ? <AccountActivationForm message={message} title={title} loading={loading} error={error} savePassword={savePassword}></AccountActivationForm>
                : <AccountActivationConfirmation message={message}></AccountActivationConfirmation>
            }
        </main>
    )

}

export default AccountActivation