import { useEffect, useState } from "react";

export default function useSetSelectedConversationUserAndUserSubscriber(subscribers) {
    const [user, setUser] = useState(false)
    const [userSubscriber, setUserSubscriber] = useState(null)

    useEffect(() => {
        subscribers.forEach(subscriber => {
            if (subscriber.user !== null) {
                setUser(subscriber.user);
                setUserSubscriber(subscriber)
            }
        })
    }, [subscribers])

    return { user, userSubscriber }
}