import React from 'react'
import TicketItem from './TicketItem'

const Tickets = ({tickets, user}) => {

    const ticketList = tickets.map((ticket) => (
        <TicketItem ticket={ticket} key={ticket.id}></TicketItem>
    ))

    return (
        <div className="px-3">
            <div className="bg-gray py-1 px-2">
                <h4 className="urbane-medium text-lg text-center">Safety and other tickets</h4>
            </div>
            <div className="flex flex-row justify-center flex-wrap p-8">
            {tickets.length > 0
                ? ticketList
                : <p>{user.full_name} hasnt added any tickets yet</p>
            }
            </div>

        </div>
    )

}

export default Tickets