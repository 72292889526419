const INITIAL_STATE = {
    user: null,
    channelId: null,
    messages: [],
}

const SelectedConversationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'SET_SELECTED_CONVERSATION':
            let { user, channelId } = state;
            user = action.user;
            channelId = action.channelId;

            return { ...state, user, channelId }

        case 'LOAD_NEW_CHAT_MESSAGES':

            state.chats = state.chats.map(chat => {
                if (chat.unique_id === action.chat_uuid) {
                    chat.messages.unshift(action.new_messages)
                }

                return chat
            })

            return { ...state }

        case 'SET_CHAT_MESSAGES':
            state.messages = [...action.messages];

            return { ...state }

        case 'PUSH_NEW_MESSAGE':
            // make sure no existing message with same id exists
            let existingMessage = state.messages.find(message => message.id === action.message.id)

            if (!existingMessage) {   
                state.messages = [...state.messages, action.message];
            }

            return { ...state };

        case 'PUSH_NEW_MESSAGES':
            state.messages = state.messages.concat(action.messages);

            return { ...state }

        case 'DELETE_CHAT_MESSAGE':

            state.chats = state.chats.map(chat => {
                if (chat.unique_id === action.chat_uuid) {
                    chat.messages = chat.messages.filter(message => message.id !== action.message_id)
                }

                return chat
            })

            return { ...state }

        default:
            return state
    }
}

export default SelectedConversationReducer;