import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import MatchIndicator from '../MatchIndicator';

const SafetyTicketsMatch = ({user}) => {

    const tickets = useSelector(state => state.advancedSearch.tickets);
    const search = useSelector(state => state.advancedSearch.search)
    const [matches, setMatches] = useState(0);
    const searchType = useSelector(state => state.advancedSearch.search_type);

    useEffect(() => {
        let foundMatches = 0;
        if (searchType === 'generic') {
            user.credentials.forEach(credential => {
                if (credential.name.toLowerCase().includes(search.toLowerCase())) {
                    foundMatches++;
                }
            })
        } else {
            tickets.forEach(ticket => {
                user.credentials.forEach(credential => {
                    if (credential.name.toLowerCase().includes(ticket.toLowerCase())) {
                        foundMatches++;
                    }
                })
            })
        }
        setMatches(foundMatches);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tickets, user, search, searchType])

    const match = () => {
        if(searchType === 'generic'){
            if(matches > 0){
                return 'match'
            } else {
                return ''
            }
        } else {
            if(tickets.length > 0){
                if(matches >= tickets.length){
                    return 'match'
                } else if(matches > 0) {
                    return 'partial_match'
                } else {
                    return 'no_match'
                }
            } else {
                return '';
            }
        }
    }
    
    return (
        <MatchIndicator match={match()}></MatchIndicator>
        
    )

}

export default SafetyTicketsMatch