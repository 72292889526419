const INITIAL_STATE = {
    locations: [],
    trades: [],
    tickets: [],
    skills: [],
    availability: null,
    search: null,
    results: [],
    page: 1,
    total_results: 0,
    total_pages: 0,
    search_type: null,
    errors: [],
    loaded_ids: [],
    endOfInitialResults: false,
}

const AdvancedSearchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'SET_LOCATION_SEARCH':

            let { locations } = state;
            locations = [...locations, action.location];
            return { ...state, locations }

        case 'REMOVE_LOCATION_SEARCH':
            state.locations = state.locations.filter(trade => trade !== action.item.name);

            return { ...state }

        case 'SET_TRADE_SEARCH':

            let { trades } = state;
            trades = [...trades, action.trade];
            return { ...state, trades }

        case 'REMOVE_TRADE_SEARCH':
            state.trades = state.trades.filter(trade => trade.name !== action.item.name && trade.level !== action.item.level);

            return { ...state }

        case 'SET_SKILL_SEARCH':
            let { skills } = state;
            skills = [...skills, action.skill];
            return { ...state, skills }

        case 'REMOVE_SKILL_SEARCH':
            state.skills = state.skills.filter(skill => skill !== action.item.name);

            return { ...state }

        case 'REMOVE_TICKET_SEARCH':
            state.tickets = state.tickets.filter(ticket => ticket !== action.item.name);

            return { ...state }

        case 'SET_TICKET_SEARCH':

            let { tickets } = state;
            tickets = [...tickets, action.ticket];
            return { ...state, tickets }

        case 'SET_AVAILABILITY_SEARCH':
            let { availability } = state;

            availability = action.availability;

            return { ...state, availability }

        case 'REMOVE_AVAILABILITY_SEARCH':
            state.availability = null

            return { ...state }

        case 'INCREMENT_PAGE':
            let { page } = state;
            page = page + 1;

            return { ...state, page }

        case 'SET_GENERIC_SEARCH':

            let { search, results, total_results, total_pages, search_type, loaded_ids, endOfInitialResults } = state;
            search = action.search;
            results = action.results;
            total_results = action.total_results;
            total_pages = action.total_pages;
            state.page = 1;
            search_type = 'generic';
            loaded_ids = action.results.map(user => user.id)
            endOfInitialResults = false;
            state.locations =  [];
            state.trades =  [];
            state.tickets =  [];
            state.skills =  [];
            state.availability =  null;
            return { ...state, search, results, total_results, total_pages, search_type, loaded_ids, endOfInitialResults }


        case 'SET_ADVANCED_SEARCH':
            state.results = action.results;
            state.total_results = action.total_results;
            state.total_pages = action.total_pages;
            state.page = 1;
            state.search_type = 'advanced'
            state.loaded_ids = action.results.map(user => user.id)
            state.search = null;

            if (state.page >= state.total_pages) {
                state.endOfInitialResults = true;
            } else {
                state.endOfInitialResults = false;
            }

            return { ...state }

        case 'CLEAR_ADVANCED_SEARCH_RESULTS':
            state.results = []

            return { ...state }

        case 'APPEND_SEARCH_RESULTS':

            state.total_results = action.total_results;
            state.total_pages = action.total_pages;
            action.results.forEach(result => {
                if (!state.loaded_ids.includes(result.id)) {
                    state.results.push(result)
                    state.loaded_ids.push(result.id);
                }
            })

            //if the initial results have reached the last page then set the initial results as complete
            if (state.page >= state.total_pages && !state.endOfInitialResults) {
                state.endOfInitialResults = true;
            }
            if(state.endOfInitialResults){
                state.page = 'N/A'
            }

            return { ...state }

        case 'SET_END_OF_INITIAL_RESULTS':
            state.endOfInitialResults = true;

            return { ...state }


        default:
            return state
    }
}

export default AdvancedSearchReducer