import React, { useState } from 'react'
import useFilteredSearchConversations from '../../../../hooks/conversations/useFilteredSearchConversations'
import useSortedConversationsByLastMessage from '../../../../hooks/conversations/useSortedConversationsByLastMessage'
import ConversationListHeader from './partials/ConversationListHeader'
import ConversationListItem from './partials/ConversationListItem'

const ConversationList = () => {

    const [search, setSearch] = useState('');
    const { filtered } = useFilteredSearchConversations(search)
    const { sorted } = useSortedConversationsByLastMessage(filtered)

    const conversationsList = sorted.map((conversation) => (
        <ConversationListItem conversation={conversation} key={conversation.id}></ConversationListItem>
    ))

    return (
        <div className="h-full rounded-md shadow-lg bg-offWhite w-1/3 mr-2 overflow-hidden">
            <div className="flex flex-col overflow-hidden h-full">
                <div className="sticky top-0 left-0 bg-offWhite px-3 py-3 drop-shadow-md filter">
                    <ConversationListHeader search={search} setSearch={setSearch}></ConversationListHeader>
                </div>
                <div className="overflow-y-auto py-4 px-3 flex-1">
                    {conversationsList}
                </div>
            </div>
        </div>
    )

}

export default ConversationList