import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

export default function useTradeFilters() {
    const [trade, setTrade] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [levelOfAchievement, setLevelOfAchievement] = useState('');
    const dispatch = useDispatch()
    const tradeFilters = useSelector(state => state.advancedSearch.trades)

    const clearForm = () => {
        setTrade('')
        setLevelOfAchievement('')
    }

    const setFilter = () => {
        if (trade) {
            if (tradeFilters.length > 0) {
                setError(true);
                setErrorMessage('Only one trade can be added');
                setTrade('')
            } else if(checkDuplicate()) {
                setError(true);
                setErrorMessage('Trade already added');
                setTrade('')
            } else {
                dispatch({
                    type: 'SET_TRADE_SEARCH',
                    trade: {
                        name: trade,
                        level: levelOfAchievement
                    },
                })
                setTrade('');
                setLevelOfAchievement(0);
            }
        }
    }

    const checkDuplicate = () => {
        let exists = false;
        tradeFilters.forEach(filter => {
            let existingTradeFilter = filter.level + " " +  filter.name;
            let newTradeFilter = levelOfAchievement + " " +  trade;
            if(existingTradeFilter.includes(newTradeFilter)){
                exists = true;
            }
        });
        return exists;
    }

    return {
        trade, setTrade, error, setError, errorMessage, setErrorMessage, setFilter, levelOfAchievement, setLevelOfAchievement, clearForm
    }
}