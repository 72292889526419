import { useDispatch, useSelector } from "react-redux";

export default function useSetTradesSearch() {
    const trades = useSelector(state => state.advancedSearch.trades)
    const dispatch = useDispatch()
    
    return (trade, levelOfAchievement = '') => {
        if (!trade) return

        if (trade && !levelOfAchievement) {
            if (trades.includes(trade)) return
        }

        const found = trades.find(tr => tr.name == trade && tr.level == levelOfAchievement)
        
        if (found) {
            return
        }

        if (trade && levelOfAchievement) {
            if (trades.includes(levelOfAchievement + ' ' + trade)) return
        }

        dispatch({
            type: 'SET_TRADE_SEARCH',
            trade: {
                name: trade,
                level: levelOfAchievement
            },
        })        
    }
}