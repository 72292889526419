import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {  useParams } from 'react-router'
import { getProfile } from '../../data/Profiles/UserProfile'
import Loading from '../Utilities/Loading'
import Navigation from '../Utilities/Navigation/Navigation'
import Education from './partials/Education/Education'
import ProfileMeta from './partials/ProfileMeta/ProfileMeta'
import Skills from './partials/Skills/Skills'
import Tickets from './partials/Tickets/Tickets'
import WorkExperience from './partials/WorkExperience/WorkExperience'

const UserProfile = () => {

    const { id } = useParams();
    const [profile, setProfile] = useState({});
    const [loaded, setLoaded] = useState(false);
    const tradeslink_user = useSelector(state => state.tradeslink_user.user)

    useEffect(() => {
        let params = {}

        if (tradeslink_user && tradeslink_user.id) {
            params['authenticated_tradeslink_user'] = tradeslink_user.id
        }

        getProfile(id, params).then(response => {
            setProfile(response.data.profile);
            setLoaded(true);
        }).catch(error => {
            console.error(error);
        })
    }, [id])

    return (
        <>
            <Navigation></Navigation>
            {loaded ?
                <main className="container mx-auto bg-offWhite rounded-md mt-8 shadow-lg">
                    <ProfileMeta profile={profile} userId={id}></ProfileMeta>
                    <Education education={profile.education} user={profile.user}></Education>
                    <WorkExperience workExperience={profile.work_experience} user={profile.user}></WorkExperience>
                    <Tickets tickets={profile.tickets_and_other} user={profile.user}></Tickets>
                    <Skills skills={profile.skills} user={profile.user}></Skills>
                </main>
                :
                <Loading></Loading>
            }
        </>
    )

}

export default UserProfile