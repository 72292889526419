import { useDispatch } from "react-redux"

export default function useRemoveFilter() {
    const dispatch = useDispatch()

    return (filter) => {
        dispatch({
            type: `REMOVE_${filter.type}_SEARCH`, 
            item: filter,
        })
    }
}