import { useEffect, useState } from "react"

export default function useSkillsErrors(skillFilters) {
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        setError(false);
        setErrorMessage("")
    }, [skillFilters])

    return {error, setError, errorMessage, setErrorMessage}
}